import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 100;
	src: url('/fonts/Silka_Thin.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 200;
	src: url('/fonts/Silka_Extra_Light.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 300;
	src: url('/fonts/Silka_Light.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/Silka_Regular.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 500;
	src: url('/fonts/Silka_Medium.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/Silka_SemiBold.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/Silka_Bold.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: normal;
	font-weight: 800;
	src: url('/fonts/Silka_Black.otf') format('opentype');
}

@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 100;
	src: url('/fonts/Silka_Thin_Italic.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 200;
	src: url('/fonts/Silka_Extra_Light_Italic.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 300;
	src: url('/fonts/Silka_Light_Italic.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 400;
	src: url('/fonts/Silka_Regular_Italic.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 500;
	src: url('/fonts/Silka_Medium_Italic.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 600;
	src: url('/fonts/Silka_SemiBold_Italic.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 700;
	src: url('/fonts/Silka_Bold_Italic.otf') format('opentype');
}
@font-face {
  	font-display: block;
	font-family: 'Silka';
	font-style: italic;
	font-weight: 800;
	src: url('/fonts/Silka_Black_Italic.otf') format('opentype');
}

	html {
		overflow-y: ${props => (props.isMenuVisible ? 'hidden' : 'auto')};
		overflow-x: hidden;
	}
	body {
		width: 100%;
		padding: 0;
		margin: 0;
		overflow-x: hidden;
		font-family: ${({ theme }) => theme.fontFamily.mainFont}; 
		background-color: ${({ theme, isPageWhite }) => (isPageWhite ? theme.colors.pmmWhite : theme.colors.pmmDarkViolet)};
		-webkit-tap-highlight-color: transparent;
	}
	
	button {
		font-family: ${({ theme }) => theme.fontFamily.mainFont};
		&:focus {
			outline: none;
		}
	}
	
	a {
		&:focus {
		outline: none;
		}
	}

	*, *::before, *::after{
		box-sizing: border-box;
	}
	
	*::placeholder {
		font-family: ${({ theme }) => theme.fontFamily.mainFont};
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: 0; 
		padding: 0;
	}
	
	p {
		margin: 0;
		padding: 0;
		//TODO: dodać color darkest blue
	}
	
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	.white {
		color: ${({ theme }) => theme.colors.pmmWhite};
	}
	.dark-blue {
		color: ${({ theme }) => theme.colors.pmmDarkBlue};
	}
	.darkest-blue {
		color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	}
	.letter-spacing-0 {
		letter-spacing: 0;
	}
/////////// h1 styles ////////////////////////////////	
	h1 {
		font-size: ${({ theme }) => theme.fontSize.h1_360};
		line-height: ${({ theme }) => theme.lineHeight.h1_360};
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		&.white {
			color: ${({ theme }) => theme.colors.pmmWhite};
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h1_375};
			line-height: ${({ theme }) => theme.lineHeight.h1_375};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h1_768};
			line-height: ${({ theme }) => theme.lineHeight.h1_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h1_1280};
			line-height: ${({ theme }) => theme.lineHeight.h1_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h1_1366};
			line-height: ${({ theme }) => theme.lineHeight.h1_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h1_1920};
			line-height: ${({ theme }) => theme.lineHeight.h1_1920};
		}
	}
/////////// h1 styles ////////////////////////////////	

/////////// h2 styles ////////////////////////////////	
	h2 {
		font-size: ${({ theme }) => theme.fontSize.h2_360};
		line-height: ${({ theme }) => theme.lineHeight.h2_360};
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		&.light {
			font-weight: ${({ theme }) => theme.fontWeight.light};
		}
		&.bold {
			font-weight: ${({ theme }) => theme.fontWeight.bold};
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h2_375};
			line-height: ${({ theme }) => theme.lineHeight.h2_375};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h2_768};
			line-height: ${({ theme }) => theme.lineHeight.h2_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h2_1280};
			line-height: ${({ theme }) => theme.lineHeight.h2_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h2_1366};
			line-height: ${({ theme }) => theme.lineHeight.h2_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h2_1920};
			line-height: ${({ theme }) => theme.lineHeight.h2_1920};
		}
	}
/////////// h2 styles ////////////////////////////////	

/////////// h3 styles ////////////////////////////////	
	h3 {
		font-size: ${({ theme }) => theme.fontSize.h3_360};
		line-height: ${({ theme }) => theme.lineHeight.h3_360};
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		&.white {
			color: ${({ theme }) => theme.colors.pmmWhite};
		}
		&.bold {
			font-weight: ${({ theme }) => theme.fontWeight.bold};
		} 
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h3_375};
			line-height: ${({ theme }) => theme.lineHeight.h3_375};
		}
		@media(min-width: 425px) {
			font-size: ${({ theme }) => theme.fontSize.h3_425};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h3_768};
			line-height: ${({ theme }) => theme.lineHeight.h3_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h3_1280};
			line-height: ${({ theme }) => theme.lineHeight.h3_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h3_1366};
			line-height: ${({ theme }) => theme.lineHeight.h3_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h3_1920};
			line-height: ${({ theme }) => theme.lineHeight.h3_1920};
		}
		&.negative-letter-spacing {
			letter-spacing: ${({ theme }) => theme.letterSpacing.h3_360_semi_bold};
			@media(min-width: 375px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h3_375_semi_bold};
			}
			@media(min-width: 768px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h3_768_semi_bold};
			}
			@media(min-width: 1280px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h3_1280_semi_bold};
			}
			@media(min-width: 1366px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h3_1366_semi_bold};
			}
			@media(min-width: 1920px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h3_1920_semi_bold};
			}
		}
	}
/////////// h3 styles ////////////////////////////////		

/////////// h4 styles ////////////////////////////////	
	h4 {
		font-size: ${({ theme }) => theme.fontSize.h4_360};
		line-height: ${({ theme }) => theme.lineHeight.h4_360};
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		&.white {
			color: ${({ theme }) => theme.colors.pmmWhite};
		}
		&.medium {
			font-weight: ${({ theme }) => theme.fontWeight.medium};
		}
		&.bold {
			font-weight: ${({ theme }) => theme.fontWeight.bold};
		}
		&.semi-bold {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h4_375};
			line-height: ${({ theme }) => theme.lineHeight.h4_375};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h4_768};
			line-height: ${({ theme }) => theme.lineHeight.h4_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h4_1280};
			line-height: ${({ theme }) => theme.lineHeight.h4_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h4_1366};
			line-height: ${({ theme }) => theme.lineHeight.h4_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h4_1920};
			line-height: ${({ theme }) => theme.lineHeight.h4_1920};
		}
	}
/////////// h5 styles ////////////////////////////////

	h5 {
		font-size: ${({ theme }) => theme.fontSize.h5_360};
		line-height: ${({ theme }) => theme.lineHeight.h5_360};
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		letter-spacing: ${({ theme }) => theme.letterSpacing.h5_360_bold};
		&.header-with-line {
			letter-spacing: 0;
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		}
		&.white {
			color: ${({ theme }) => theme.colors.pmmWhite};
		}
		&.grey {
			color: ${({ theme }) => theme.colors.pmmPlaceHolderGrey};
		}
		&.orange {
			color: ${({ theme }) => theme.colors.pmmOrange};
		}
		&.dark-blue {
			color: ${({ theme }) => theme.colors.pmmDarkestBlue};
		}
		&.light {
			font-weight: ${({ theme }) => theme.fontWeight.light};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_360_light};
			@media(min-width: 375px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_375_light};
			}
			@media(min-width: 768px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_768_light};
			}
			@media(min-width: 1280px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1280_light};
			}
			@media(min-width: 1366px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1366_light};
			}
			@media(min-width: 1920px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1920_light};
			}
		}
		&.regular {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
		&.medium {
			font-weight: ${({ theme }) => theme.fontWeight.medium};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_360_medium};
			@media(min-width: 375px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_375_medium};
			}
			@media(min-width: 768px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_768_medium};
			}
			@media(min-width: 1280px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1280_medium};
			}
			@media(min-width: 1366px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1366_medium};
			}
			@media(min-width: 1920px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1920_medium};
			}
		}
		&.semi-bold {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_360_semi_bold};
			@media(min-width: 375px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_375_semi_bold};
			}
			@media(min-width: 768px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_768_semi_bold};
			}
			@media(min-width: 1280px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1280_semi_bold};
			}
			@media(min-width: 1366px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1366_semi_bold};
			}
			@media(min-width: 1920px) {
				letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1920_semi_bold};
			}
		}
		&.line-height-32 {
			@media(min-width: 375px) {
				line-height: 26px;
			}
			@media(min-width: 768px) {
				line-height: 26px;
			}
			@media(min-width: 1280px) {
				line-height: 27px;
			}
			@media(min-width: 1366px) {
				line-height: 28px;
			}
			@media(min-width: 1920px) {
				line-height: 32px;
			}
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h5_375};
			line-height: ${({ theme }) => theme.lineHeight.h5_375};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_375_bold};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h5_768};
			line-height: ${({ theme }) => theme.lineHeight.h5_768};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_768_bold};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h5_1280};
			line-height: ${({ theme }) => theme.lineHeight.h5_1280};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1280_bold};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h5_1366};
			line-height: ${({ theme }) => theme.lineHeight.h5_1366};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1366_bold};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h5_1920};
			line-height: ${({ theme }) => theme.lineHeight.h5_1920};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1920_bold};
		}
	}
/////////// h5 styles ////////////////////////////////

/////////// h6 styles ////////////////////////////////

	h6 {
		font-size: ${({ theme }) => theme.fontSize.h6_360};
		line-height: ${({ theme }) => theme.lineHeight.h6_360};
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		&.white {
			color: ${({ theme }) => theme.colors.pmmWhite};
		}
		&.orange {
			color: ${({ theme }) => theme.colors.pmmInputBorderOrange};
		}
		&.regular {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
		&.medium {
			font-weight: ${({ theme }) => theme.fontWeight.medium};
		}
		&.semi-bold {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h6_375};
			line-height: ${({ theme }) => theme.lineHeight.h6_375};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h6_768};
			line-height: ${({ theme }) => theme.lineHeight.h6_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h6_1280};
			line-height: ${({ theme }) => theme.lineHeight.h6_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h6_1366};
			line-height: ${({ theme }) => theme.lineHeight.h6_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h6_1920};
			line-height: ${({ theme }) => theme.lineHeight.h6_1920};
		}
		
		&.line-height-28 {
			line-height: 26px;
		
			@media(min-width: 375px) {
				line-height: 24px;
			}
			@media(min-width: 768px) {
				line-height: 24px;
			}
			@media(min-width: 1280px) {
				line-height: 24px;
			}
			@media(min-width: 1366px) {
				line-height: 24px;
			}
			@media(min-width: 1920px) {
				line-height: 28px;
			}
		}
	}
/////////// h6 styles ////////////////////////////////			

/////////// h7 & h8 styles ////////////////////////////////

	p {
		font-size: ${({ theme }) => theme.fontSize.p_375};
		line-height: ${({ theme }) => theme.lineHeight.p_375};
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		&.semi-bold {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		}
		&.regular {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.p_375};
			line-height: ${({ theme }) => theme.lineHeight.p_375};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.p_768};
			line-height: ${({ theme }) => theme.lineHeight.p_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.p_1280};
			line-height: ${({ theme }) => theme.lineHeight.p_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.p_1366};
			line-height: ${({ theme }) => theme.lineHeight.p_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.p_1920};
			line-height: ${({ theme }) => theme.lineHeight.p_1920};
		}
		&.subpage-paragraph {
			line-height: 22px;
			@media(min-width: 375px) {
				line-height: 22px;
			}
			@media(min-width: 768px) {
				line-height: 25px;
			}
			@media(min-width: 1280px) {
				line-height: 25px;
			}
			@media(min-width: 1366px) {
				line-height: 25px;
			}
			@media(min-width: 1920px) {
				line-height: 29px;
			}
		}
		&.custom-lh {
			line-height: 24px;
			@media(min-width: 375px) {
				line-height: 24px;
			}
			@media(min-width: 768px) {
				line-height: 27px;
			}
			@media(min-width: 1280px) {
				line-height: 27px;
			}
			@media(min-width: 1366px) {
				line-height: 28px;
			}
			@media(min-width: 1920px) {
				line-height: 34px;
			}
		}
		&.h7 {
			font-size: ${({ theme }) => theme.fontSize.h7_360};
			line-height: ${({ theme }) => theme.lineHeight.h7_360};
			font-weight: ${({ theme }) => theme.fontWeight.bold};
			&.regular {
				font-weight: ${({ theme }) => theme.fontWeight.regular};
			}
			&.black {
				font-weight: ${({ theme }) => theme.fontWeight.black};
			}
			&.white {
				color: ${({ theme }) => theme.colors.pmmWhite};
			}
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.h7_375};
				line-height: ${({ theme }) => theme.lineHeight.h7_375};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.h7_768};
				line-height: ${({ theme }) => theme.lineHeight.h7_768};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.h7_1280};
				line-height: ${({ theme }) => theme.lineHeight.h7_1280};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.h7_1366};
				line-height: ${({ theme }) => theme.lineHeight.h7_1366};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.h7_1920};
				line-height: ${({ theme }) => theme.lineHeight.h7_1920};
			}
		}
		&.h8 {
			font-size: ${({ theme }) => theme.fontSize.h8_360};
			line-height: ${({ theme }) => theme.lineHeight.h8_360};
			font-weight: ${({ theme }) => theme.fontWeight.medium};
			&.white {
				color: ${({ theme }) => theme.colors.pmmWhite};
			}
			&.dark-blue {
				color: ${({ theme }) => theme.colors.pmmDarkestBlue};
			}
			&.black {
				font-weight: ${({ theme }) => theme.fontWeight.black};
				letter-spacing: ${({ theme }) => theme.letterSpacing.h8_360_black};
				@media(min-width: 375px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h8_375_black};
				}
				@media(min-width: 768px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h8_768_black};
				}
				@media(min-width: 1280px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h8_1280_black};
				}
				@media(min-width: 1366px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h8_1366_black};
				}
				@media(min-width: 1920px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h8_1920_black};
				}
			}
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.h8_375};
				line-height: ${({ theme }) => theme.lineHeight.h8_375};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.h8_768};
				line-height: ${({ theme }) => theme.lineHeight.h8_768};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1280};
				line-height: ${({ theme }) => theme.lineHeight.h8_1280};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1366};
				line-height: ${({ theme }) => theme.lineHeight.h8_1366};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1920};
				line-height: ${({ theme }) => theme.lineHeight.h8_1920};
			}
		}
	}
/////////// h7 & h8 styles ////////////////////////////////		

	button.h6 {
		font-size: ${({ theme }) => theme.fontSize.h6_360};
		line-height: ${({ theme }) => theme.lineHeight.h6_360};
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		&.white {
			color: ${({ theme }) => theme.colors.pmmWhite};
		}
		&.orange {
			color: ${({ theme }) => theme.colors.pmmInputBorderOrange};
		}
		&.medium {
			font-weight: ${({ theme }) => theme.fontWeight.medium};
		}
		&.semi-bold {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h6_375};
			line-height: ${({ theme }) => theme.lineHeight.h6_375};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h6_768};
			line-height: ${({ theme }) => theme.lineHeight.h6_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h6_1280};
			line-height: ${({ theme }) => theme.lineHeight.h6_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h6_1366};
			line-height: ${({ theme }) => theme.lineHeight.h6_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h6_1920};
			line-height: ${({ theme }) => theme.lineHeight.h6_1920};
		}
	}

/////////// redactor styles ////////////////////////////////

	div.header-h2.light {
		div {
			font-size: ${({ theme }) => theme.fontSize.h2_360};
			line-height: ${({ theme }) => theme.lineHeight.h2_360};
			font-weight: ${({ theme }) => theme.fontWeight.light};
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.h2_375};
				line-height: ${({ theme }) => theme.lineHeight.h2_375};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.h2_768};
				line-height: ${({ theme }) => theme.lineHeight.h2_768};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.h2_1280};
				line-height: ${({ theme }) => theme.lineHeight.h2_1280};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.h2_1366};
				line-height: ${({ theme }) => theme.lineHeight.h2_1366};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.h2_1920};
				line-height: ${({ theme }) => theme.lineHeight.h2_1920};
			}
			}
		}
		
	div.header-h3 {
		div {
			font-size: ${({ theme }) => theme.fontSize.h3_360};
			line-height: ${({ theme }) => theme.lineHeight.h3_360};
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
			&.white {
				color: ${({ theme }) => theme.colors.pmmWhite};
			}
			&.bold {
				font-weight: ${({ theme }) => theme.fontWeight.bold};
			} 
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.h3_375};
				line-height: ${({ theme }) => theme.lineHeight.h3_375};
			}
			@media(min-width: 425px) {
				font-size: ${({ theme }) => theme.fontSize.h3_425};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.h3_768};
				line-height: ${({ theme }) => theme.lineHeight.h3_768};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.h3_1280};
				line-height: ${({ theme }) => theme.lineHeight.h3_1280};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.h3_1366};
				line-height: ${({ theme }) => theme.lineHeight.h3_1366};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.h3_1920};
				line-height: ${({ theme }) => theme.lineHeight.h3_1920};
			}
		}
	}	
		
	div.header-h4 {
		div {
			font-size: ${({ theme }) => theme.fontSize.h4_360};
			line-height: ${({ theme }) => theme.lineHeight.h4_360};
			font-weight: ${({ theme }) => theme.fontWeight.regular};
			&.white {
				color: ${({ theme }) => theme.colors.pmmWhite};
			}
			&.bold {
				font-weight: ${({ theme }) => theme.fontWeight.bold};
			}
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.h4_375};
				line-height: ${({ theme }) => theme.lineHeight.h4_375};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.h4_768};
				line-height: ${({ theme }) => theme.lineHeight.h4_768};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.h4_1280};
				line-height: ${({ theme }) => theme.lineHeight.h4_1280};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.h4_1366};
				line-height: ${({ theme }) => theme.lineHeight.h4_1366};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.h4_1920};
				line-height: ${({ theme }) => theme.lineHeight.h4_1920};
			}
			}
		}
		
	div.header-h8 {
		span {
			font-size: ${({ theme }) => theme.fontSize.h8_360};
			line-height: ${({ theme }) => theme.lineHeight.h8_360};
			font-weight: ${({ theme }) => theme.fontWeight.medium};
			}
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.h8_375};
				line-height: ${({ theme }) => theme.lineHeight.h8_375};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.h8_768};
				line-height: ${({ theme }) => theme.lineHeight.h8_768};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1280};
				line-height: ${({ theme }) => theme.lineHeight.h8_1280};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1366};
				line-height: ${({ theme }) => theme.lineHeight.h8_1366};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1920};
				line-height: ${({ theme }) => theme.lineHeight.h8_1920};
			}
		}	
	
	div.header-h8.black {
		span {
			font-weight: ${({ theme }) => theme.fontWeight.black};
			font-size: ${({ theme }) => theme.fontSize.h8_360};
			line-height: ${({ theme }) => theme.lineHeight.h8_360};
			letter-spacing: ${({ theme }) => theme.letterSpacing.h8_360_black};
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.h8_375};
				line-height: ${({ theme }) => theme.lineHeight.h8_375};
				letter-spacing: ${({ theme }) => theme.letterSpacing.h8_375_black};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.h8_768};
				line-height: ${({ theme }) => theme.lineHeight.h8_768};
				letter-spacing: ${({ theme }) => theme.letterSpacing.h8_768_black};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1280};
				line-height: ${({ theme }) => theme.lineHeight.h8_1280};
				letter-spacing: ${({ theme }) => theme.letterSpacing.h8_1280_black};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1366};
				line-height: ${({ theme }) => theme.lineHeight.h8_1366};
				letter-spacing: ${({ theme }) => theme.letterSpacing.h8_1366_black};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.h8_1920};
				line-height: ${({ theme }) => theme.lineHeight.h8_1920};
				letter-spacing: ${({ theme }) => theme.letterSpacing.h8_1920_black};
			}
		}
	}
		
	span.header-h5.grey {
		 	&:after {
				font-size: ${({ theme }) => theme.fontSize.h5_360};
				line-height: ${({ theme }) => theme.lineHeight.h5_360};
				font-weight: ${({ theme }) => theme.fontWeight.light};
				color: ${({ theme }) => theme.colors.pmmPlaceHolderGrey};
				@media(min-width: 375px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h5_375_light};
					font-size: ${({ theme }) => theme.fontSize.h5_375};
					line-height: ${({ theme }) => theme.lineHeight.h5_375};
				}
				@media(min-width: 768px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h5_768_light};
					font-size: ${({ theme }) => theme.fontSize.h5_768};
					line-height: ${({ theme }) => theme.lineHeight.h5_768};
				}
				@media(min-width: 1280px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1280_light};
					font-size: ${({ theme }) => theme.fontSize.h5_1280};
					line-height: ${({ theme }) => theme.lineHeight.h5_1280};
				}
				@media(min-width: 1366px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1366_light};
					font-size: ${({ theme }) => theme.fontSize.h5_1366};
					line-height: ${({ theme }) => theme.lineHeight.h5_1366};
				}
				@media(min-width: 1920px) {
					letter-spacing: ${({ theme }) => theme.letterSpacing.h5_1920_light};
					font-size: ${({ theme }) => theme.fontSize.h5_1920};
					line-height: ${({ theme }) => theme.lineHeight.h5_1920};
				}
			}	
	}
	
	div.paragraph {
		div, a {
			font-size: ${({ theme }) => theme.fontSize.p_375};
			line-height: ${({ theme }) => theme.lineHeight.p_375};
			font-weight: ${({ theme }) => theme.fontWeight.medium};
			&.semi-bold {
				font-weight: ${({ theme }) => theme.fontWeight.semiBold};
			}
			&.bold {
				font-weight: ${({ theme }) => theme.fontWeight.bold};
			}
			@media(min-width: 375px) {
				font-size: ${({ theme }) => theme.fontSize.p_375};
				line-height: ${({ theme }) => theme.lineHeight.p_375};
			}
			@media(min-width: 768px) {
				font-size: ${({ theme }) => theme.fontSize.p_768};
				line-height: ${({ theme }) => theme.lineHeight.p_768};
			}
			@media(min-width: 1280px) {
				font-size: ${({ theme }) => theme.fontSize.p_1280};
				line-height: ${({ theme }) => theme.lineHeight.p_1280};
			}
			@media(min-width: 1366px) {
				font-size: ${({ theme }) => theme.fontSize.p_1366};
				line-height: ${({ theme }) => theme.lineHeight.p_1366};
			}
			@media(min-width: 1920px) {
				font-size: ${({ theme }) => theme.fontSize.p_1920};
				line-height: ${({ theme }) => theme.lineHeight.p_1920};
			}
		}
	}
	
	div.paragraph.who-we-help {
		div {
			line-height: 28px;
			font-weight: ${({ theme }) => theme.fontWeight.regular};
			@media(min-width: 375px) {
				line-height: 28px;
			}
			@media(min-width: 768px) {
				line-height: 30px;
			}
			@media(min-width: 1280px) {
				line-height: 30px;
			}
			@media(min-width: 1366px) {
				line-height: 30px;
			}
			@media(min-width: 1920px) {
				line-height: 34px;
			}
			}
	}
	
	div.paragraph.text-in-border {
		div, a {
			line-height: 28px;
			font-weight: ${({ theme }) => theme.fontWeight.medium};
			@media(min-width: 375px) {
				line-height: 28px;
			}
			@media(min-width: 768px) {
				line-height: 30px;
			}
			@media(min-width: 1280px) {
				line-height: 30px;
			}
			@media(min-width: 1366px) {
				line-height: 30px;
			}
			@media(min-width: 1920px) {
				line-height: 34px;
			}
		}
	}

	label.paragraph.who-we-help {
		line-height: 28px;
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		@media(min-width: 375px) {
			line-height: 28px;
		}
		@media(min-width: 768px) {
			line-height: 30px;
		}
		@media(min-width: 1280px) {
			line-height: 30px;
		}
		@media(min-width: 1366px) {
			line-height: 30px;
		}
		@media(min-width: 1920px) {
			line-height: 34px;
		}
	}

	label.paragraph {
		font-size: ${({ theme }) => theme.fontSize.p_375};
		line-height: ${({ theme }) => theme.lineHeight.p_375};
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		&.semi-bold {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		}
		&.bold {
			font-weight: ${({ theme }) => theme.fontWeight.bold};
		}
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.p_375};
			line-height: ${({ theme }) => theme.lineHeight.p_375};
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.p_768};
			line-height: ${({ theme }) => theme.lineHeight.p_768};
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.p_1280};
			line-height: ${({ theme }) => theme.lineHeight.p_1280};
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.p_1366};
			line-height: ${({ theme }) => theme.lineHeight.p_1366};
		}
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.p_1920};
			line-height: ${({ theme }) => theme.lineHeight.p_1920};
		}
	}

.custom-redactor{
	a {
		color:  ${({ theme }) => theme.colors.pmmOrange};
	}
	// ol {
	// 	margin: 60px 0 0;
	// 	padding: 0;
	// 	list-style: none;
	// 	counter-reset: counter;
	//	
	// 	li {
	// 		padding-bottom: 40px;
	// 		counter-increment: counter;
	// 		text-indent: -62px;
	// 		margin-left: 60px;
	//		
	// 		//.indentation {
	// 			position: relative;
	// 			//margin-left: 60px;
	// 			&:before{
	// 				content: counter(counter);
	// 				padding-right: 50px;
	// 			}
	// 			&:after{
	// 				content: '';
	// 				position: absolute;
	// 				top: 22px;
	// 				left: -40px;
	// 				height: 2px;
	// 				width: 10px;
	// 				background-color: ${({ theme }) => theme.colors.pmmOrange};
	// 			}
	// 		//}
	// 		//.no-indentation {
	// 		//	position: relative;
	// 			&:before {
	// 				content: counter(counter);
	// 				padding-right: 50px;
	// 			}
	// 			&:after {
	// 				content: '';
	// 				position: absolute;
	// 				top: 22px;
	// 				left: -40px;
	// 				height: 2px;
	// 				width: 10px;
	// 				background-color: ${({ theme }) => theme.colors.pmmOrange};
	// 			}
	// 		//}
	// 		&:last-of-type{
	// 			padding-bottom: 0;
	// 		}
	// 	}
	// //}

	ol {
		list-style: none;
		counter-reset: counter;
		padding-left: 40px;
		@media(min-width: 768px) {
			padding-left: 0;
		}
	  
	}
	ol li {
		counter-increment: counter;
		position: relative;
		margin-bottom: 40px;
	}
	ol li::before {
		content: '';
		position: absolute;
		left: -40px;
		top: 0;
		width: 30px;
		height: 30px;
		border: 1px solid ${({ theme }) => theme.colors.pmmDarkestBlue};
		border-radius: 50%;
		font-weight: bold;
	  	@media(min-width: 768px) {
	    	width: 36px;
	    	height: 36px;
          	left: -50px;
          	top: -3px;  
	    }
		@media(min-width: 1920px) {
			left: -50px;
			top: 0;
		}
	}
	ol li::after {
		content: counter(counter);
		font-size: 18px;
		line-height: 23px;
		position: absolute;
		left: -31px;
		top: 3px;
		font-weight: ${({ theme }) => theme.fontWeight.black};
		color: ${({ theme }) => theme.colors.pmmOrange};
	  	@media(min-width: 768px) {
        	font-size: 22px;
	   	 	line-height: 27px;
          	left: -39px;
		  	top: 2px;
	  	}
		@media(min-width: 1920px) {
			left: -39px;
			top: 5px;  
		}
	}
	ul {
		margin-left: 60px;
		margin-top: 60px;
		li {
			padding-bottom: 40px;
	
			//.indentation {
				position: relative;
			//	margin-left: 60px;
				
				&:before{
					content: '';
					position: absolute;
					top: 16px;
					left: -60px;
					height: 3px;
					width: 30px;
					background-color: ${({ theme }) => theme.colors.pmmOrange};
				}
			//}
			//.no-indentation {
			//	position: relative;
				
				&:before{
					content: '';
					position: absolute;
					top: 16px;
					left: -60px;
					height: 3px;
					width: 30px;
					background-color: ${({ theme }) => theme.colors.pmmOrange};
				}
			//}
			&:last-of-type{
				padding-bottom: 0;
			}
		}
	}
	
  span.orange-bg {
    display: inline;
    background: #FF4124;
    padding: 6px 0;
    box-shadow: 3px 0 0 #FF4124, -3px 0 0 #FF4124;
    color: white;
  }
	mark {
		background-color: ${({ theme }) => theme.colors.pmmOrange};
		color: inherit;
	}
}	
/////////// redactor styles ////////////////////////////////		


`;

export default GlobalStyle;
