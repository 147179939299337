import styled from "styled-components";
import posed from "react-pose";
import pose from "react-pose"
import CircleWhite from "../../icons/circle-white.svg";
import CircleOrange from "../../icons/circle-orange.svg";

export const SelectWrapper = styled.div`

	display: none;
	
	.placeholder {
		color: ${({theme}) => theme.colors.pmmOrange};
		font-weight: ${({theme}) => theme.fontWeight.black};
		font-size: 14px;
		margin-left: 7px;
		font-style: normal;
		@media(min-width: 375px){
			font-size: 18px;
		}
	}
	
	@media(min-width: 768px){
		display: flex;
			position: relative;
	}
	
	&.display-always {
		display: flex;
	}
}
`

export const SelectedOption = styled.h3`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.light};
	border-bottom: 1px solid ${({theme}) => theme.colors.pmmOrange};
	font-style: italic;
	align-items: center;
	justify-content: center;
	overflow: visible;
	cursor: pointer;
	width: 100%;
	max-width: calc(100vw - 20px);
	text-align: center;

	
	@media(min-width: 768px){
		position: relative;
		margin-left: 10px;
		border-bottom: 2px solid ${({theme}) => theme.colors.pmmOrange};
		white-space: nowrap;
	}
	@media(min-width: 1280px){
		font-weight: ${({theme}) => theme.fontWeight.regular};
	}
  
	&.cash-flow{
		margin-left: 8px;
		font-size: 14px;
		font-style: normal;
	  	line-height: 25px;
		@media(min-width: 375px){
			font-size: 18px;
		}
		@media(min-width: 768px){
			font-size: 24px;	
			line-height: 32px;
		}
	}
	&.lang {
		padding: 0;
		margin: 0;
		border-bottom: none;
		position: relative;
		width: 41px;
		height: 41px;
		font-style: normal;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: ${({theme}) => theme.fontSize.f6};
		line-height:${({theme}) => theme.lineHeight.line14};
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
		
		&:before {
			content: '';
			position: absolute;
			background-image: url(${CircleWhite});
			background-repeat: no-repeat;
			background-size: contain;
			width: 41px;
			height: 41px;
			transition: transform 0.15s ease-in-out;
		}
		
		&:hover {
			color: ${({theme}) => theme.colors.pmmOrange};
			&:before {
				background-image: url(${CircleOrange});
				background-repeat: no-repeat;
				background-size: contain;
				transform: scale(1.113);
			}
		}
		
		
		&.menu-visible {
				cursor: none;
			&:hover {
			color: ${({theme}) => theme.colors.pmmWhite};
				&:before {
					background-image: url(${CircleWhite});
					transform: scale(1.113);
				}
			}
		}
		
		&.menu-not-visible {
			&:hover {
				color: ${({theme}) => theme.colors.pmmOrange};

				&:before {
					transform: scale(1.113);
				}
			}
		}
		&.menu-not-visible.edge {
			&:hover {
				color: ${({theme}) => theme.colors.pmmOrange};

				&:before {
					transform: scale(1);
				}
			}
		}
		@media(min-width: 1280px){
			width: 26px;
			height: 26px;
			font-size: ${({theme}) => theme.fontSize.f11};
			line-height:${({theme}) => theme.lineHeight.line11};
			
			&:before {
				content: '';
				position: absolute;
				width: 26px;
				height: 26px;
				transition: transform 0.15s ease-in-out;
			}
		}
		@media(min-width: 1920px){
			width: 34px;
			height: 34px;
			font-size: ${({theme}) => theme.fontSize.f4};
			
			&:before {
				width: 34px;
				height: 34px;
			}
		}
	}
`

export const PosedSelectWindow = posed.div({
	enter: {
		opacity: 1,
		transform: 'scaleY(1)',
		animationTimingFunction: 'cubic-bezier(.23,1,.32,1)',
		animationFillMode: 'forwards',
		transformOrigin: '0 0',
		animationIterationCount: 1,
		animationPlayState: 'running',
		animationDuration: 450,
	},

	exit: {
		opacity: 0,
		transform: 'scaleY(0)',
		animationTimingFunction: 'cubic-bezier(.23,1,.32,1)',
		animationFillMode: 'forwards',
		transformOrigin: '0 0',
		animationIterationCount: 1,
		animationPlayState: 'running',
		animationDuration: 450,
		animationDelay: 100
	}
})

export const SelectWindow = styled(PosedSelectWindow)`
	position: absolute;
	z-index: 101;
	top: -30px;
	box-sizing: border-box;
	left: 10px;
	color: ${({theme}) => theme.colors.pmmDarkViolet};
	font-size: ${({theme}) => theme.fontSize.f6};
	line-height: ${({theme}) => theme.lineHeight.line6};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	background-color: ${({theme}) => theme.colors.pmmWhite};
	display: table;
	padding: 20px;
	overflow: hidden;
	
	
	&.lang {
	font-size: ${({theme}) => theme.fontSize.f4};
		top: -20px;
		left: -10px;
		padding: 10px;
	}
	&.cash-flow {
		font-size: 14px;
		padding: 0;
		top: -10px;
		left: 0;
		
		@media(min-width: 375px) {
			font-size: 18px;
		}
		@media(min-width: 768px) {
			top: -30px;
			font-size: 24px;
			padding: 20px;
		}
	}
`

export const PosedOption = pose.li({
	enter: {
		y: 0, opacity: 1,
		transition: {
			delay: 100,
			duration: 450,
			opacity: {duration: 0},
		}
	},
	exit: {
		y: -20, opacity: 0,
		transition: {
			delay: 0,
			duration: 350,
			opacity: {duration: 0},
		}
	},
})

export const OptionList = styled.ul`
position: relative;
`

export const Option = styled(PosedOption)`
	min-height: 50px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	//white-space: nowrap;
	transform: translate3d(0,-20px,0);
	
	&.lang {
		text-transform: uppercase;
		padding: 0 10px;
	}
	
	&:hover {
		color: ${({theme}) => theme.colors.pmmWhite};
		background-color: ${({theme}) => theme.colors.pmmOrange};
	}
	&.cash-flow {
		padding: 0 10px;
		@media(min-width: 768px) {
			padding: 0 20px;
		}
	}
`
