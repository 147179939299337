import styled from "styled-components";
import React, {Component} from "react";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const WholeInfoWrapper = styled.div`
	position: absolute;
	width: 100vw;
	margin-left: -10px;
	display: block;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	padding: 30px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	z-index: 100;
	box-shadow: 0 6px 8px 0 rgba(37, 20, 71, 0.47);
	top: -90px;
	@media(min-width: 425px) {
		max-width: 550px;
		margin-left: 0;
	}     
	@media(min-width: 768px) {
		width: 410px;
		top: -280px;
		left: 0;
		margin-left: 0;
		max-width: unset;
	}
	@media(min-width: 1024px) {
		width: 500px;
	}
	@media(min-width: 1280px) {
		top: -300px;
		left: 0;
	}
	@media(min-width: 1366px) {
		width: 600px;
	}
	@media(min-width: 1920px) {
		top: -430px;
		left: 0;
		width: 774px;
		padding: 60px 33px 45px 40px;
	}	
	&.at-footer {
		left: 0;
		top: -100px;
		@media(min-width: 768px) {
			top: -330px;
		}
		@media(min-width: 1280px) {
			left: unset;
			right: 0;
		}
		@media(min-width: 1920px) {
			top: -450px;
		}
	}
	&.at-contact {
		margin-left: -15px;
		@media(min-width: 425px) {
			margin-left: -30px;
			max-width: 550px;
		}
		@media(min-width: 600px){
			margin-left: 0;
		}
		@media(min-width: 768px) {
			top: -300px;
			margin-left: 0;
			max-width: unset;
		}
		@media(min-width: 1280px) {
			top: -350px;
		}
		@media(min-width: 1920px) {
			top: -500px;
		}
	}
	&.at-payment{
		top: 100px;
		margin-left: -12px;
		@media(min-width: 425px) {
			margin-left: -2px;
		} 
		@media(min-width: 768px) {	
			left: 0;
			top: 65px;
			width: 504px;
		} 
		@media(min-width: 1920px) {
			top: 50px;
			width: 690px;
		}
	}
`;

const ClosingIcon = styled.img`
	position: absolute;
	top: 20px;
	right: 20px;
	width: 30px;
	cursor: pointer;
	z-index: 10001;
	@media(min-width: 1920px) {
		top: 30px;
		right: 20px;
		width: 36px;
	}
`;

const InfoTitle = styled.p`
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	margin-bottom: 20px;
	padding-right: 25px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	@media(min-width: 1920px) {
		margin-bottom: 35px;
	}
`;

const InfoText = styled.h5`
		max-height: 200px;
		overflow-y: scroll;
		padding-right: 40px;
		letter-spacing: 0;
		&::-webkit-scrollbar {
			width: 10px;
			height: 80px;
			padding-left: 30px;
		}
		&::-webkit-scrollbar-track {   
    		opacity: 0;
    		
		}
		&::-webkit-scrollbar-thumb {
		    -webkit-border-radius: 10px;
		    border-radius: 10px;
		    background: ${({theme}) => theme.colors.pmmOrange};
		}
	//line-height: 20px !important;
	@media(min-width: 1920px) {
		line-height: 28px !important;
		max-height: 303px;
		&::-webkit-scrollbar {
			width: 10px;
			height: 80px;
			padding-left: 30px;
		}
		&::-webkit-scrollbar-track {   
    		opacity: 0;
    		
		}
		&::-webkit-scrollbar-thumb {
		    -webkit-border-radius: 10px;
		    border-radius: 10px;
		    background: ${({theme}) => theme.colors.pmmOrange};
		}
	}
`

class WholeInfoProcessingPersonalData extends Component {
	render() {
		const {isSpanClicked, processingData, isFooter, isContact, isPayment} = this.props;
		const processingDataHeader = (processingData.naglowek) || '';
		const processingDataDescriptionNewsletter = (processingData.opisNewsletter) || '';
		const processingDataDescriptionContact = (processingData.opisKontakt) || '';
		const processingDataDescriptionPayments = (processingData.opisPlatnosci) || '';
		const processingDataIconClose = (processingData.ikonaZamkniecia && processingData.ikonaZamkniecia[0] && processingData.ikonaZamkniecia[0].url) || '';
		const classNameDependingOnDisplayingPlace = (isFooter) ? 'at-footer' : (isContact) ? 'at-contact' : (isPayment) ? 'at-payment' :'';
		const finalDescription = (isContact) ? processingDataDescriptionContact : (isPayment) ? processingDataDescriptionPayments : (isFooter) ? processingDataDescriptionNewsletter : processingDataDescriptionNewsletter;
		return (
			<WholeInfoWrapper className={classNameDependingOnDisplayingPlace}>
				<ClosingIcon src={processingDataIconClose} onClick={isSpanClicked}/>
				<InfoTitle className="h7">{processingDataHeader}</InfoTitle>
				<InfoText className="regular dark-blue" dangerouslySetInnerHTML={getHtmlFromRedactorField(finalDescription)}/>
			</WholeInfoWrapper>
		)
	}
}

export default WholeInfoProcessingPersonalData
