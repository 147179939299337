import React, { Component } from 'react';
import styled from 'styled-components';
import TickPicture from '../../images/newsletter/checkbox-tick.svg';

const StyledCheckboxInput = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: flex-start;
	width: 100%;
	height: 50px;
	cursor: pointer;
	z-index: 0;
	margin-top: 10px;
	transition: opacity 0.3s;
	opacity: ${({ isCheckboxVisible }) => (isCheckboxVisible ? '1' : '0')};
	@media (min-width: 768px) {
		width: 100%;
		margin-top: 0;
	}
	@media (min-width: 1280px) {
		width: ${({ isFooter }) => (isFooter ? '95%' : '60%')};
		margin-top: 0;
	}
	@media (min-width: 1600px) {
		width: ${({ isFooter }) => (isFooter ? '95%' : '74%')};
	}
	input {
		margin: 0 10px 0 0;
		width: 42px !important;
		height: 30px !important;
		z-index: 20;
		cursor: pointer;
		@media (min-width: 375px) {
			margin: 0;
		}
		@media (min-width: 1920px) {
			margin-top: 12px;
		}
	}
	div {
		p {
			padding-left: 5px;
			font-size: 12px;
			line-height: 22px;
			font-weight: 400;
			color: ${({ theme }) => theme.colors.pmmPlaceHolderGrey};
			@media (min-width: 768px) {
				//padding: 0px 10px;
			}
			@media (min-width: 1920px) {
				padding-top: 16px;
			}
			span {
				text-decoration: underline;
				color: ${({ theme }) => theme.colors.pmmOrange};
				padding-left: 5px;
			}
		}
	}
	.custom-checkbox {
		opacity: 0;
	}
`;

const CheckboxLabel = styled.label`
	position: absolute;
	background-color: ${({ theme }) => theme.colors.pmmOrange};
	border-radius: 4px;
	border: none;
	opacity: 1;
	z-index: 4;
	width: 20px;
	height: 20px;
	top: 6px;
	cursor: pointer;
	outline: none;
	padding: 10px;
	@media (min-width: 1920px) {
		top: 20px;
	}
`;
const TickPictureWrapper = styled.div`
	position: absolute;
	z-index: 5;
	left: 5px;
	bottom: 29px;
	width: 11px;
	height: 9px;
	background-size: cover;
	background-position: center;
	background-image: url(${({ bgImg }) => bgImg});
	@media (min-width: 1920px) {
		bottom: 15px;
	}
`;

class CheckboxInfo extends Component {
	render() {
		const {
			required,
			checked,
			isInputActiveAndCheckboxVisible,
			isCheckboxClicked,
			isSpanClicked,
			paragraphText,
			spanText,
			isFooter,
		} = this.props;
		const isCheckboxVisible = isInputActiveAndCheckboxVisible;
		const photo = checked ? TickPicture : '';
		return (
			<StyledCheckboxInput isCheckboxVisible={isCheckboxVisible} isFooter={isFooter}>
				<input
					type="checkbox"
					className="custom-checkbox"
					onClick={isCheckboxClicked}
					id="newsletter"
					checked={checked}
					required={required}
				/>
				<CheckboxLabel htmlFor="newsletter" onClick={isCheckboxClicked} />
				<TickPictureWrapper checked={checked} bgImg={photo} />
				<div style={{ marginLeft: '5px' }}>
					<p onClick={isCheckboxClicked}>
						{paragraphText}
						<span onClick={isSpanClicked}>{spanText}</span>
					</p>
				</div>
			</StyledCheckboxInput>
		);
	}
}

export default CheckboxInfo;
