import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"
import getSlug from "../../utils/getSlug"
import IconMenu from "../iconMenu/IconMenu";
import ChangeLanguage from "../changeLanguage/ChangeLanguage";
import InfoCard from "./InfoCard";
import ButtonWithText from "../common/ButtonWithText";
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';

const NavigationWrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100vw;
	overflow-y: auto;
	z-index: 1000;
	top: 0;
	left: 0;
	display: block;
	padding-left: 90px;

	@media(min-width: 1280px){
		position: fixed;
		height: 100vh;
		padding-left: 210px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	@media(min-width: 1920px){
		padding-left: 310px;
	}
`

const NavigationList = styled.li`
	//display: flex;
	display: block;
	flex-direction: column;
	padding-top: 200px;
	-webkit-tap-highlight-color: transparent;
	//cursor: pointer;
	//cursor: none;
	position: relative;

	&:hover, &:focus {
		//font-weight: ${({theme}) => theme.fontWeight.bold};
		color:  ${({theme}) => theme.colors.pmmDarkestBlue};
		outline: none;
	}
	@media(min-width: 768px){
		padding-top: 250px;
	}
	@media(min-width: 1280px){
		padding-top: 80px;
	}
`

const MenuLink = styled(Link)`
	-webkit-tap-highlight-color: transparent;
	position: relative;
	display: table;
	padding-bottom: 20px;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-size: ${({theme}) => theme.fontSize.f13};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	line-height:  ${({theme}) => theme.lineHeight.line6};
	text-decoration: none;
	cursor: none;

	&.cursor-default {
		cursor: default !important;
	}
	&:focus {
		font-weight: ${({theme}) => theme.fontWeight.bold};
		outline: none;
	}
	
	@media(min-width: 768px){
		font-size: ${({theme}) => theme.fontSize.f5};
		&:hover, &:focus {
			color:  ${({theme}) => theme.colors.pmmDarkestBlue};
			font-size: 40px;
			outline: none;
		}
	}
	@media(min-width: 1280px){
		padding-bottom: 10px;
		transition:  .15s ease-in-out;
	}
	@media(min-width: 1920px){
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
		font-size: ${({theme}) => theme.fontSize.f2};
		line-height:  ${({theme}) => theme.lineHeight.line2};
		padding-bottom: 10px;
		&:hover, &:focus {
			font-size: 50px;
		}
	}
`


const BtnWrapper = styled.div`
	margin-top: 20px;
	margin-left: -20px;
	display: flex;
	margin-bottom: 10px;
	
	@media(min-width: 768px){
		display: none;
	}
`

const MenuInfoLabel = styled.label`
	display: flex;
	font-size: ${({theme}) => theme.fontSize.f12};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	color: ${({theme}) => theme.colors.pmmWhite};
	line-height: ${({theme}) => theme.lineHeight.line12};
	text-transform: uppercase;
	position: relative;
	padding-top: 40px;
	
	&:before {
		content: '';
		position: absolute;
		left: -53px;
		bottom: 2px;
		width: 33px;
		height: 1px;
		background-color: ${({theme}) => theme.colors.pmmWhite};
	}
	
	@media(min-width: 768px){
		display: none;
	}
`

class Nav extends React.Component {

	constructor(props) {
		super(props)
		this.escFunction = this.escFunction.bind(this)
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.props.hideMenu()
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false)
	}

	handleMouseMoveOnLink() {
		this.props.customMenuCursor.classList.add('bigger');
	}

	handleMouseOutOnLink() {
		this.props.customMenuCursor.classList.remove('bigger');
	}

	render() {
		const {hideMenu, menuData, activeSite, menuGlobal, firstOtherLangPathArray, pageContext, socialMediaData, isMenuStickyVisible, translations, menuStatsFodActiveProjects, randomInfoNumber, isMenuVisible} = this.props
		const getMenuItems = (menuItems) => menuItems.map((menuItem) => {

			const slug = (menuItem.wybierzPodstrone && menuItem.wybierzPodstrone[0] && menuItem.wybierzPodstrone[0].slug) || ""

			return {
				text: menuItem.title,
				to: slug !== "" ? getSlug(slug, activeSite) : "",
			}
		})

		const menuItems = getMenuItems(menuData)

		const menuBtn = (menuGlobal && menuGlobal[0] && menuGlobal[0].menuPrzycisk && menuGlobal[0].menuPrzycisk[0]) || ""
		const menuText = menuBtn.przyciskTekst || ""
		const toBtn = (menuBtn && menuBtn.przyciskWpis && menuBtn.przyciskWpis[0] && menuBtn.przyciskWpis[0].slug) || ""
		const hrefBtn = (menuBtn && menuBtn.przyciskLink) || ""
		const socialMediaLabel = (translations && translations[0] && translations[0].tlumaczenieSocialMedia) || ""
		const langLabel = (translations && translations[0] && translations[0].tlumaczenieJezyki) || ""

		return (
			<NavigationWrapper>
				<NavigationList>
					{menuItems && menuItems.map((menuItem, key) =>
						<MenuLink onClick={hideMenu} onMouseMove={!isMobile ? this.handleMouseMoveOnLink.bind(this) : null}
								  onMouseOut={!isMobile ? this.handleMouseOutOnLink.bind(this) : null} key={key} to={`/${menuItem.to}`}
								  className={isMenuVisible ? '' : 'cursor-default'}>{menuItem.text}</MenuLink>
					)}
				</NavigationList>
				<BtnWrapper>
					<ButtonWithText href={hrefBtn} onClick={hideMenu} isMenuVisible={isMenuVisible} textBtn={menuText}
									to={`/${getSlug(toBtn, activeSite)}`}/>
				</BtnWrapper>
				<MenuInfoLabel>{socialMediaLabel}</MenuInfoLabel>
				<IconMenu displayOnMobile={true} firstOtherLangPathArray={firstOtherLangPathArray}
						  pageContext={pageContext}
						  socialMediaData={socialMediaData} menuGlobal={menuGlobal} activeSite={activeSite}
						  hideMenu={hideMenu}/>
				<MenuInfoLabel>{langLabel}</MenuInfoLabel>
				<ChangeLanguage firstOtherLangPathArray={firstOtherLangPathArray} pageContext={pageContext}
								isMenuStickyVisible={isMenuStickyVisible} displayForAllSizes={false} isInlineMenu/>
				<InfoCard randomInfoNumber={randomInfoNumber} menuStatsFodActiveProjects={menuStatsFodActiveProjects}
						  activeSite={activeSite}
						  translations={translations}
						  hideMenu={hideMenu}  handleMouseMoveOnLink={!isMobile ? this.handleMouseMoveOnLink.bind(this) : null}
								  handleMouseOutOnLink={!isMobile ? this.handleMouseOutOnLink.bind(this) : null}/>
			</NavigationWrapper>
		)
	}
}

export default connect(state => ({
	isMenuVisible: state.app.isMenuVisible
}), null)(Nav)
