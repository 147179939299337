import React from "react"
import styled from "styled-components"
import {Link} from "gatsby";
import {isMobile} from 'react-device-detect';

const BasicTransparentBtn = styled.button`
	cursor: pointer;
	background-color: transparent;
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-size: 16px;
	line-height: 19px;
	font-weight: ${({theme}) => theme.fontWeight.bold};
	color: ${({theme}) => theme.colors.pmmOrange};
	border: 2px solid ${({theme}) => theme.colors.pmmOrange};
	border-radius: 150px;
	padding: 10px 30px;
	text-transform: uppercase;
	transition: margin .25s ease-out, padding .25s ease-out;
	text-decoration: none;
	
	&.white-text {
		color: ${({theme}) => theme.colors.pmmWhite};
	}
	
	&.hover {
		&.animate {
		  padding: 10px 40px;
		  margin-left: -10px;
		  margin-right: -10px;
		}
		
		&:hover {
		    padding: 10px 40px;
			margin-left: -10px;
			 margin-right: -10px;
		}
	}
	//@media(min-width: 1024px) {
	//	&.animate {
	//	  padding: 10px 40px;
	//	  margin-left: -10px;
	//	  margin-right: -10px;
	//	}
	//	
	//	&:hover {
	//	    padding: 10px 40px;
	//		margin-left: -10px;
	//		 margin-right: -10px;
	//	}
	//}
`;

const TransparentBgBtn = (props) => {

		const {textBtn, btnClassName, to, href, onClick, onMouseOver, onMouseOut, type, form} = props;

		return (
			<>
				{href && <BasicTransparentBtn href={href} as={"a"} className={isMobile ? `${btnClassName}` : `${btnClassName} hover`} onClick={onClick} onFocus={onMouseOver} onBlur={onMouseOut} onMouseOver={onMouseOver} onMouseOut={onMouseOut} form={form} type={type} target="_blank" rel="noopener noreferrer">{textBtn}</BasicTransparentBtn>}
				{to && <BasicTransparentBtn as={Link} to={to} className={isMobile ? `${btnClassName}` : `${btnClassName} hover`}
											onClick={onClick} onFocus={onMouseOver} onBlur={onMouseOut} onMouseOver={onMouseOver} onMouseOut={onMouseOut} form={form} type={type}>{textBtn}</BasicTransparentBtn>}
				{!href && !to && <BasicTransparentBtn className={isMobile ? `${btnClassName}` : `${btnClassName} hover`} onClick={onClick} onFocus={onMouseOver} onBlur={onMouseOut} onMouseOver={onMouseOver} onMouseOut={onMouseOut} form={form} type={type}>{textBtn}</BasicTransparentBtn>}
			</>
		)
}

export default TransparentBgBtn
