import React, {Component} from "react"
import posed, {PoseGroup} from "react-pose"
import styled from "styled-components"
import PMM_logo from "../../../images/newsletter/PMM_logo_modal.svg"
import X_sign from "../../../images/newsletter/X-sign.svg"
import BasicButton from "../BasicButton";

const PosedModal = posed.div({
	enter: {
		opacity: 1,
		delay: 450,
		transition: {
			ease: 'linear',
			default: {duration: 300},
		},
	},
	exit: {
		opacity: 0,
		transition: {duration: 150},

	},
});

const PosedShade = posed.div({
	enter: {
		opacity: 1,
		y: 0,
		x: 0,
		transition: {
			ease: 'linear',
			duration: 250
		},
	},
	exit: {
		opacity: 1,
		x: 0,
		y: "-100%",
		transition: {
			ease: 'linear',
			duration: 250
		}

	},
});

const StyledPosedShade = styled(PosedShade)`
	z-index: 10000;
	background-color: ${({theme}) => theme.colors.pmmModalDarkBackground};
	position: fixed !important;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
`;

const StyledPosedModal = styled(PosedModal)`
	text-align: center;
	position: fixed !important;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 11000;
	display: flex;
	justify-content: center;
	align-items: center;
 `;

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
`;

const XWrapper = styled.div`
	position: fixed;
	top: 45px;
	right: 25px;
	width: 19px;
	height: 19px;
	cursor: pointer;
	background-size: cover;
    background-position: center;
    background-image: url( ${({bgXImg}) => bgXImg});
    
    @media(min-width: 768px){
    	top: 50px;
		right: 20px;
	}
	@media(min-width: 1280px){
		top: 40px;
		right: 25px;
	}
	@media(min-width: 1920px){
		top: 60px;
		right: 60px;
	}
`;

const PictureWrapper = styled.div`
	margin: 0 auto;
	width: 51px;
	height: 62px;
	background-size: cover;
    background-position: center;
    background-image: url( ${({bgImg}) => bgImg});
    position: relative;
`;

const Header = styled.h3`
	color: ${({theme}) => theme.colors.pmmWhite};
	position: relative;
	text-align: center;
	padding: 30px 25px 20px;
	width: 100%;
	@media(min-width: 768px){
		padding: 30px 25px 15px;
	}
	@media(min-width: 1920px){
		padding: 40px 10% 20px;
	}
`;

const Description = styled.p`
	font-size: ${({theme}) => theme.fontSize.f14};
	line-height: ${({theme}) => theme.lineHeight.line21};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	padding: 0 25px;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	position: relative;
	text-align: center;
	width: 100%;
	@media(min-width: 768px){
		font-size: ${({theme}) => theme.fontSize.f4};
		line-height: ${({theme}) => theme.lineHeight.line15};
	}
	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f6};
		line-height: ${({theme}) => theme.lineHeight.line3};
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	@media(min-width: 1280px){

	}
	@media(min-width: 1920px){
		margin-top: 45px;	
	}
`;

class Popup extends Component {

	constructor(props) {
		super(props)
		this.escFunction = this.escFunction.bind(this)
	}

	escFunction(event) {
		if (this.props.isVisible && event.keyCode === 27) {
			this.setState(this.initialState);
			this.props.hide()
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false)
	}

	render() {
		const {newsletterModalData, isVisible, hide, isSubscriberAdded} = this.props;
		const newsletterModalNaglowekOk = (newsletterModalData && newsletterModalData.newsletterModalNaglowekOk) || "";
		const newsletterModalNaglowekBlad = (newsletterModalData && newsletterModalData.newsletterModalNaglowekBlad) || "";
		const newsletterModalOpisOk = (newsletterModalData && newsletterModalData.newsletterModalOpisOk) || "";
		const newsletterModalOpisBlad = (newsletterModalData && newsletterModalData.newsletterModalOpisBlad) || "";
		const newsletterModalNapisNaPrzyciskuOk = (newsletterModalData && newsletterModalData.newsletterModalNapisNaPrzyciskuOk) || "";
		const newsletterModalNapisNaPrzyciskuBlad = (newsletterModalData && newsletterModalData.newsletterModalNapisNaPrzyciskuBlad) || "";

		const modalFinalHeader = (isSubscriberAdded) ? newsletterModalNaglowekOk : newsletterModalNaglowekBlad;
		const modalFinalDescription = (isSubscriberAdded) ? newsletterModalOpisOk : newsletterModalOpisBlad;
		const modalFinalButtonText = (isSubscriberAdded) ? newsletterModalNapisNaPrzyciskuOk : newsletterModalNapisNaPrzyciskuBlad;
		return (
			<PoseGroup>
				{isVisible && [
					<StyledPosedShade pose={isVisible ? "enter" : "exit"} key="shade"/>,
					<StyledPosedModal pose={isVisible ? "enter" : "exit"} key="modal">
						<ModalWrapper>
							<XWrapper bgXImg={X_sign} onClick={hide}/>
							<PictureWrapper bgImg={PMM_logo}/>
							<Header>{modalFinalHeader}</Header>
							<Description className="custom-lh">{modalFinalDescription}</Description>
							<ButtonWrapper onClick={hide}>
								<BasicButton onClick={this.setState(this.initialState)} textBtn={modalFinalButtonText}/>
							</ButtonWrapper>
						</ModalWrapper>
					</StyledPosedModal>
				]}
			</PoseGroup>
		)
	}
}

export default Popup
