import styled from "styled-components";

export const NoBackgroundBtn = styled.button`
	cursor: pointer;
	background-color: transparent;
	border: none;
	font-size:  ${({theme}) => theme.fontSize.f9};
	font-weight: ${({theme}) => theme.fontWeight.black};
	color: ${({theme}) => theme.colors.pmmWhite};
	text-transform: uppercase;
	text-decoration: none;
	border-bottom: 1px solid ${({theme}) => theme.colors.pmmWhite};
	padding: 0;
	transition: margin .1s ease-in-out, padding .1s ease-in-out;
	
	&:hover {
		padding: 0 10px;
		margin-left: -10px;
		margin-right: -10px
	}
}
	@media(min-width: 768px){
		margin-top: 20px;
	}
	
	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f3};
		line-height: ${({theme}) => theme.lineHeight.line4};
	}
	@media(min-width: 2300px){
		font-size: ${({theme}) => theme.fontSize.f18};
	}
`
