export const scrollToSectionName = (slug) => {
	const element = document.getElementById(slug)
	if(window.scrollY < element.offsetTop) {
		window.scroll({
			behavior: "smooth",
			top: element.offsetTop,
		})
	}
	else {
		window.scroll({
			behavior: "smooth",
			top: element.offsetTop-100,
		})
	}
};
