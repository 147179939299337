import React from "react"
import styled from "styled-components"
import {navigate} from "gatsby"
import {connect} from "react-redux"
import {changeLang} from "../../state/app"
import getSlug from "../../utils/getSlug"
import "react-dropdown/style.css"
import { SelectedOption, SelectWrapper} from "../common/CustomSelector";
import CircleWhite from "../../icons/circle-white.svg";
import CircleOrange from "../../icons/circle-orange.svg";
import CircleBlue from "../../icons/circle-blue.svg";

const CheckboxWrapper = styled.div`
	&.is-sticky {
		#lang-selector {
			color: ${({theme}) => theme.colors.pmmDarkBlue};

			&:before {
				background-image: url(${CircleBlue});
			}
			&:hover {
				color: ${({theme}) => theme.colors.pmmOrange};
				&:before {
					background-image: url(${CircleOrange});
					transform: scale(1.113);
				}
			}	
		}
	}
	&.is-sticky-and-visible {
		@media(min-width: 1280px){
			#lang-selector {
				color: ${({theme}) => theme.colors.pmmDarkBlue};
				
				&:before {
					background-image: url(${CircleBlue});
				}
				&:hover {
					color: ${({theme}) => theme.colors.pmmDarkBlue};
					&:before {
						transform: scale(1.113);
					}
				}	
			}
		}
	}

`

const LangInlineWrapper = styled.div`
	display: flex;
	
	&.menu {
		@media(min-width: 768px){
			display: none;
		}
	}
`

const LangInline = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-size:  ${({theme}) => theme.fontSize.f4};
	text-transform: uppercase;
	margin-right: 10px;
	//cursor: pointer;
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight:  ${({theme}) => theme.fontWeight.bold};
	
	&:before {
		content: '';
		position: absolute;
		background-image: url(${CircleWhite});
		background-repeat: no-repeat;
		background-size: contain;
		width: 34px;
		height: 34px;
		transition: transform 0.15s ease-in-out;
	}
	
	&.footer {
		&.active {
			color: ${({theme,}) => theme.colors.pmmOrange};
			&:before {
				background-image: url(${CircleOrange});
			}
		}
	}
	
	&.menu {
		margin: 35px 11px 35px 0;
		height: 41px;
		width: 41px;
		font-size: ${({theme}) => theme.fontSize.f3};
		
		&:before {
				height: 41px;
		width: 41px;
		}
		
		&.active {
			color: ${({theme,}) => theme.colors.pmmDarkViolet};
			&:before {
				background-image: url(${CircleBlue});
			}
		}
	}
`

const options = [
	"pl",
	"en",
]

class ChangeLanguage extends React.Component {
	state = {
		isOldBrowserEdge: false,
	};
	// constructor(props) {
	// 	super(props)
	// }
	componentDidMount() {
		// if (typeof window !== "undefined") {
		// 	const isIE = false || !!document.documentMode;
		// 	const isEdge = !isIE && !!window.StyleMedia;
		// 	// const isOldBrowser = isIE || isEdge;
		// 	// this.setState({isOldBrowserEdge: isOldBrowser});
		// }
	}
	handleChange = (project, dispatch, pathToNavigate, lang) => {
		if (project !== lang) {
			window.localStorage.setItem("appUILang", project)
			dispatch(changeLang(project))
			navigate("/" + pathToNavigate);
		}
	}

	setStateToActiveSite = (dispatch, site) => {
		if (site === "en") {
			dispatch(changeLang(options[1]))
		} else dispatch(changeLang(options[0]))
	}


	render() {
		const {pageContext, isMenuVisible, isMenuStickyVisible, lang, dispatch, firstOtherLangPathArray, displayForAllSizes, isMenuBlue, isInline, isInlineMenu} = this.props
		const activePageId = pageContext.remoteId
		const activeSite = pageContext.site
		const firstOtherLangSite = pageContext.firstOtherLangSite
		const otherLangUrlArray = []
		const classNameInline = isInline ? 'footer' : isInlineMenu ? 'menu' : ''

		firstOtherLangPathArray && firstOtherLangPathArray.forEach(item => {
				const id = item.id
				const slug = getSlug(item.slug, firstOtherLangSite)
				const obj = {id, slug}
				otherLangUrlArray.push(obj)
			},
		)

		const otherLangUrlObject = otherLangUrlArray.reduce((prev, curr) => {
			prev[curr.id] = curr.slug
			return prev
		}, {})

		const pathToNavigate = otherLangUrlObject[activePageId]
		let isBrowserLangPl
		let localStorageLang
		this.setStateToActiveSite(dispatch, activeSite)

		if (typeof window !== "undefined") {
			localStorageLang = window.localStorage.getItem("appUILang")
			const language = localStorageLang || window.navigator.language
			isBrowserLangPl = language.includes("pl")
		}

		if (localStorageLang === null && lang !== "") {
			if (isBrowserLangPl && lang !== "pl") {
				navigate("/" + pathToNavigate)
				window.localStorage.setItem("appUILang", "pl")
			} else if (!isBrowserLangPl && lang === "pl") {
				navigate("/" + pathToNavigate)
				window.localStorage.setItem("appUILang", "en")
			}
		}
		let langToChoose = ''

		options.forEach((project) => {
			if (project !== lang) {
				langToChoose = project
			}
		})

		const iconClassName = (this.state.isOldBrowserEdge) ? "edge" : "";
		return (
			<>
				{(!isInline && !isInlineMenu) && <CheckboxWrapper
					className={(isMenuStickyVisible && isMenuVisible) ? 'is-sticky-and-visible' : (isMenuStickyVisible || isMenuBlue) ? 'is-sticky' : ''}>
					<SelectWrapper className={displayForAllSizes ? 'display-always' : ''}>
						<SelectedOption id="lang-selector"
										className={isMenuVisible ? 'lang menu-visible' : 'lang menu-not-visible' + iconClassName}
										onClick={this.handleChange.bind(this, langToChoose, dispatch, pathToNavigate, lang, isMenuVisible)}>{langToChoose}</SelectedOption>
						{/*<SelectedOption id="lang-selector"*/}
						{/*				className={isMenuVisible ? 'lang menu-visible' : 'lang menu-not-visible' + iconClassName}>{langToChoose}</SelectedOption>*/}
					</SelectWrapper>
				</CheckboxWrapper>}
				{(isInline || isInlineMenu) &&
				<LangInlineWrapper className={classNameInline}>
					{options.map((project, key) =>
						<LangInline key={key}
									onClick={this.handleChange.bind(this, project, dispatch, pathToNavigate, lang, isMenuVisible)}
									className={project === lang ? `${classNameInline} active ${iconClassName}` : `${classNameInline} ${iconClassName}`}
									isMenuVisible={isMenuVisible}>{project}</LangInline>
								// <LangInline key={key}
								// 	className={project === lang ? `${classNameInline} active ${iconClassName}` : `${classNameInline} ${iconClassName}`}
								// 	isMenuVisible={isMenuVisible}>{project}</LangInline>
					)}
				</LangInlineWrapper>}
			</>
		)
	}
}

export default connect(state => ({
	lang: state.app.lang,
	isMenuVisible: state.app.isMenuVisible,
}), null)(ChangeLanguage)

