import React, {Component} from "react"
import styled from "styled-components"
import {PoseGroup} from "react-pose"
import pose from "react-pose"
import {connect} from "react-redux"
import {changeLinkToIdFromAnotherPage, toggleMenu} from "../../state/app";
import Nav from "./Nav";
import IconMenu from "../iconMenu/IconMenu";
import getSlug from "../../utils/getSlug";
import {Link} from "gatsby";
import Scrollspy from "react-scrollspy"
import {scrollToSectionName} from "../../utils/scrollToSectionName";
import {isMobile} from 'react-device-detect';

const TopMenu = styled.div`
	position: absolute;
	padding-top: 50px;
	width: 100%;
	z-index: 1002;
	//transition: .4s;
	//transition: opacity .2s;
	transform: translateY(0);
	&.hide {
		//transform: translateY(-100px);
	}
	
	@media(min-width: 768px){
		width: calc(100% - 230px);
		margin-left: 230px;
		padding-top: 40px;
	}
	@media(min-width: 1280px){
		width: calc(100% - 500px);
		margin-left: 500px;
		padding-top: 30px;
		opacity: 1;
		&.hide {
			transition: opacity 0s;
			opacity: 0;
			//transform: translateY(-100px);
		}
	}
	@media(min-width: 1366px){
		margin-right: 20px;
	}
	@media(min-width: 1920px){
		padding-top: 40px;
		margin-right: 30px;
	}
	
	&.white {
		background-color: white;
	}
`

const StickyBtnMenu = styled.div`
	position: fixed !important;
	z-index: 2000;
	width: 100%;
	height: 60px;
	transform: translateY(-140px);
	
	&.menu-visible {
		transform: translateY(0px);
	}
	
	
	@media(min-width: 1280px){
		height: 80px;
	}
`

const StickyMenuWithBgWrapper = styled.div`
	position: fixed !important;
	z-index: 1001;
	width: 100%;
	height: 60px;
	transform: translateY(-140px);
	transition: transform 400ms ease-in-out;
	//opacity: 0;
	&.menu-visible {
		transform: translateY(0px);
		//opacity: 1;
	}
	@media(min-width: 1280px){
		height: 80px;
		
		transition: opacity 400ms ease-in-out;
	opacity: 0;
	&.menu-visible {
		transform: translateY(0px);
		opacity: 1;
	}
	}
`

const StickyMenuBg = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000; 
	height: 60px;

	&.menu-closed {
		transition-delay: 400ms;
		background-color: ${({theme}) => theme.colors.pmmWhite};
	}
	&.menu-opened {
		background-color: ${({theme}) => theme.colors.pmmOrange};
		width: calc(100% - 500px);
		margin-left: 500px;
	}
	
	@media(min-width: 1280px){
		height: 80px;
	}
`

const StickyMenuWrapper = styled.div`
	position: fixed;
	z-index: 1002;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
		
	@media(min-width: 1280px){
		height: 80px;
	}
`;

//////////////////////////////////////////////////
////////////////FLOATING MENU/////////////////////

const FloatingMenuWrapper = styled.div`
	width: 100%;
	height: 40px;
	display: none;
	background-color: ${({theme}) => theme.colors.pmmWhite}; 
	flex-direction: row;
	justify-content: center;
	padding: 0 100px;
	position: fixed;
	top: 62px;
	z-index: 1003;
	left: 0;
	@media(min-width: 768px) {
		display: flex;
	}
	@media(min-width: 1280px) {
		top: 82px;
	}
	a {
    text-decoration: none;
   }
  ul {
    display: flex;
    justify-content: space-between;
    //margin: 0 -10px;
    @media(min-width: 992px){
      //margin: 0 180px;
    }
    @media(min-width: 1200px){
      //margin: 0 225px;
    }
  }
  button{
    border: none;
    background: none;
    outline: none;
  }
    li {
    //opacity: 0.5;
    //cursor: pointer;
    &.is-current{
      opacity: 1;
      div {
      	color: ${({theme}) => theme.colors.pmmOrange};
      }
      p{
      display: none;
      @media(min-width: 1200px){
        display: flex;
        padding: 17px 0;
        background: none; 
      }   
    }
  }
  }
`;

const MenuItem = styled.div`
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
    font-family: ${({theme}) => theme.fontFamily.mainFont};
  	font-weight: ${({theme}) => theme.fontWeight.bold};
  	color: ${({theme}) => theme.colors.pmmDarkestBlue};
  	margin: 0 20px; 
`;

//////////////////////////////////////////////////

const PosedMenuWrapper = pose.div({
	enter: {
		x: 0,
		y: 0,
		delayChildren: 100,
		staggerChildren: 50,
		staggerDirection: 1,
		transition: {
			default: {duration: 300},
		},
	},
	exit: {
		staggerDirection: -1,
		staggerChildren: 20,
		x: 0,
		y: "-120%",
		transition: {
			default: {delay: 200, duration: 400},
		},
	},
})

const StyledMenuWrapper = styled(PosedMenuWrapper)`
	position: fixed !important;
	width: 100vw;
	height: 100%;
	z-index: 1000;
	//top: 0;
	right: 0;
	left: 0;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	
	@media(min-width: 768px){
	}
	@media(min-width: 1280px){
		position: fixed !important;
		height: 100vh;
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
	}
`

const LogoWrapper = styled.div`
	position: absolute;
	top: 0;
	padding-top: 25px;
	width: auto;
	height: 105px;
	left: 10px;
	z-index: 1002;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	
	&::before {
		content: '';
		width: 100vw;
		height: 100%;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		z-index: -1;
		top: 0;
		position: absolute;
	}
	
	@media(min-width: 768px){
		padding-top: 40px;
		left: 35px;
		
		&:before {
			display: none;
		}
	}
	@media(min-width: 1280px){
		padding-top: 30px;
		left: 45px;
	}
	@media(min-width: 1366px){
		padding-top: 30px;
		left: 60px;
	}
	@media(min-width: 1920px){
		padding-top: 40px;
		left: 105px;
		width: auto;
		height: 140px;
	}
	
	a {
	cursor: none;
	}
`

const LogoStyledMenu = styled.img`
	width: auto;
	height: 80px;
	@media(min-width: 1920px){
		width: auto;
		height: 100px;
	}
`

const MenuIconWithText = styled.div`
	position: absolute;
	top: 35px;
	right: 12px;
	display: flex;
	align-items: center;
	
	&.is-sticky {
		top: 50%;
		transform:translateY(-50%);
	}
	
	&.menu-opened {
		cursor: none;
	}
	&.menu-closed{
		cursor: pointer;
	}
	
	@media(min-width: 768px){
		top: 45px;
		right: 20px;
	}
	@media(min-width: 1024px){
		top: 48px;
	}
	@media(min-width: 1280px){
		top: 40px;
		
		&:hover {
			#menu-text {
				transition: transform 0.1s ease-in-out;
				transform: scale(1.1);
			}
			span {
				&:nth-of-type(2) {
					background-color: ${({theme}) => theme.colors.pmmOrange};
				}
			}
		}	
	}
	@media(min-width: 1366px){
		right: 20px;
	}
	@media(min-width: 1920px){
		top: 52px;
		right: 25px;
	}
`

const MenuText = styled.div`
	display: none;
	
	@media(min-width: 768px){
	   	user-select: none; 
		margin-right: 15px;
		display: flex;
		color: ${({theme}) => theme.colors.pmmWhite};
		font-size: ${({theme}) => theme.fontSize.f3};
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
		
		&.is-blue {
			color: ${({theme}) => theme.colors.pmmDarkestBlue};
		}
	}
	@media(min-width: 1024px){
		font-size: ${({theme}) => theme.fontSize.f12};
		margin-top: -4px;
	}
	@media(min-width: 1024px){
		margin-top: 0;
	}
	@media(min-width: 1366px){
		line-height: ${({theme}) => theme.lineHeight.line12};
	}
	@media(min-width: 1920px){
		margin-right: 20px;
		font-size: ${({theme}) => theme.fontSize.f3};
		line-height: ${({theme}) => theme.lineHeight.line4};
	}
`

const MenuIcon = styled.div`
	display: block;
	-webkit-user-select: none;
	user-select: none;
	z-index: 1002;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	width: 42px;
	height: 42px;
	padding: 15px 11px;
	border-radius: 100%;
	
	span {
		display: block; 
		width: 20px;
		height: 2px;
		margin-bottom: 3px;
		position: relative;
		background: ${({theme}) => theme.colors.pmmWhite};
		z-index: 1;
		transform-origin: 4px 0;
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					opacity 0.55s ease;
					
		&:first-of-type {
			transform-origin: 0 0;
		}
		
		&:nth-of-type(2) {
			transform-origin: 0 100%;
		}
		
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	
	&.menu-opened {
		//padding: 14px 13px;
		span {
			&:first-of-type {
				 transform: rotate(45deg) translate(1px, -1px);
			}
			&:nth-of-type(2) {
				opacity: 0;
				transform: rotate(0deg) scale(0.2, 0.2);
			}
			&:nth-of-type(3) {
				transform: rotate(-45deg) translate(-2px, 0);
			}
		}
	}
	
	@media(min-width: 768px){
		background-color: transparent;
		width: 20px;
		height: 20px;
		padding: 0;
		margin-top: 3px;
		span {
			width: 20px;
			height: 2px;
			margin-bottom: 5px;
		}
		
		&.is-blue {
			span {
				background: ${({theme}) => theme.colors.pmmDarkestBlue};
			}
		}
		&.menu-opened {
			padding: 0;
			span {
				&:first-of-type {
					 transform: rotate(45deg) translate(0, 0);
				}
				&:nth-of-type(2) {
					opacity: 0;
					transform: rotate(0deg) scale(0.2, 0.2);
				}
				&:nth-of-type(3) {
					transform: rotate(-45deg) translate(0, -4px);
				}
			}
		}	
	}
	@media(min-width: 1024px){
		width: 26px;
		height: 16px;
		margin-top: 0;
		span {
			width: 26px;
		}
		
		&.menu-opened {
			span {
				&:first-of-type {
					 transform: rotate(45deg) translate(0, 0);
				}
				&:nth-of-type(2) {
					opacity: 0;
					transform: rotate(0deg) scale(0.2, 0.2);
				}
				&:nth-of-type(3) {
					transform: rotate(-45deg) translate(-3px, -1px);
				}
			}
		}
		
		&.is-blue {
			span {
				background: ${({theme}) => theme.colors.pmmDarkestBlue};
			}
		}
	}

	@media(min-width: 1366px){
		&.menu-opened {
			margin-top: -2px;
		}
	}
	@media(min-width: 1920px){	
		height: auto;
	}
`
const LogoClickableWrapper = styled.div`
	position: absolute;
	z-index: 1002;
	height: 100%;
	padding: 0 30px;
		
	&.visible {
		transition-delay: 400ms;
		opacity: 1;
	}
	&.hidden {
		opacity: 0;
		z-index: 1000;
		pointer-events: none;
	}
	@media(min-width: 1280px){
		padding: 0 110px;
	}
`
const CustomCursor = styled.div`
	display: none;
	position: absolute;
	z-index: 9999;
	top: 0;
	left: calc(100vw - 190px);
	background-color: ${({theme}) => theme.colors.pmmWhite};
	opacity: .7;
	color: ${({theme}) => theme.colors.pmmOrange};
	font-size:  ${({theme}) => theme.fontSize.f9};
	font-weight:  ${({theme}) => theme.fontWeight.black};
	letter-spacing: 1.5px;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	pointer-events: none;
	transform: translate(-50%, -50%);
	transition: width .15s ease-in-out, height .15s ease-in-out;

	&.mouse-down {
		width: 70px !important;
		height: 70px !important;
		opacity: .2;
	}
	
	&.bigger {
		width: 130px;
		height: 130px;
		opacity: .2;
	}
	
	&.hidden {
		cursor: pointer;
		display: none !important;
	}
`

const StickyLogoStyled = styled.img`
	margin-top: 13px;
	
	@media(min-width: 1280px){
		margin-top: 25px;
	}
`

const MenuWrapperForCursor = styled.div`
	cursor: default;
	&.cursor-hidden {
		cursor: none;
	}
`

class Menu extends Component {

	randomInfoNumber;
	lastScrollY = 0;
	customMenuCursor;

	state = {
		isMenuInStickyMode: false,
		isMenuStickyVisible: false,
		isBtnStickyVisible: false,
		isOldBrowserEdge: false,
	}

	constructor(props) {
		super(props)
		this.menuActionsOnScroll = this.menuActionsOnScroll.bind(this)
		this.handleMenuIconClick = this.handleMenuIconClick.bind(this)
	}


	checkIfMenuInStickyMode() {
		const {isMenuInStickyMode} = this.state

		// if (window.scrollY > (window.innerHeight/2)) {
		if (window.scrollY > 150) {
				!isMenuInStickyMode && this.setState({isMenuInStickyMode: true})
		} else if (window.scrollY === 0) {
				isMenuInStickyMode && this.setState({isMenuInStickyMode: false})
		}
	}

	showStickyMenu() {
		const {isMenuStickyVisible, isBtnStickyVisible} = this.state
		const {linkToIdFromAnotherPage, dispatch, pageContext} = this.props

		// if (!linkToIdFromAnotherPage && window.scrollY > (window.innerHeight/2) && window.scrollY < this.lastScrollY) {
		if (!linkToIdFromAnotherPage && window.scrollY > 150 && window.scrollY < this.lastScrollY) {
				!isMenuStickyVisible && this.setState({isMenuStickyVisible: true})
				isBtnStickyVisible && this.setState({isBtnStickyVisible: false})
		} else {
				isMenuStickyVisible && this.setState({isMenuStickyVisible: false})
				if (window.scrollY > 0 && window.scrollY > this.lastScrollY && window.innerWidth >= 1280 && pageContext.remoteId !== 1371) {
					!isBtnStickyVisible && this.setState({isBtnStickyVisible: true})
				} else {
					isBtnStickyVisible && this.setState({isBtnStickyVisible: false})
				}
		}

		this.lastScrollY = window.scrollY

		if (linkToIdFromAnotherPage) {
			dispatch(changeLinkToIdFromAnotherPage(false))
		}
	}

	menuActionsOnScroll() {
		this.checkIfMenuInStickyMode()
		this.showStickyMenu()
	}

	componentDidMount() {
		window.addEventListener("scroll", this.menuActionsOnScroll)
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}

		this.customMenuCursor = document.getElementById("custom-menu-cursor");
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.menuActionsOnScroll)
	}

	setCursorOnMenu(e) {
		this.customMenuCursor.style.display = 'flex';
		this.customMenuCursor.style.top = e.pageY + 'px';
		this.customMenuCursor.style.left = e.pageX + 'px';
	}

	handleMouseMove(e) {
		if (this.props.isMenuVisible) {
			this.customMenuCursor.style.display = 'flex';
			const rect = e.currentTarget.getBoundingClientRect(),
				offsetX = e.clientX - rect.left,
				offsetY = e.clientY - rect.top;
			this.customMenuCursor.style.top = offsetY + 'px';
			this.customMenuCursor.style.left = offsetX + 'px';
		} else {
			this.customMenuCursor.style.display = 'none';
		}
	}

	handleMouseDownOnMenu(e) {
		e.preventDefault()
		if (this.props.isMenuVisible && this.customMenuCursor.classList.contains('bigger')) {
			this.customMenuCursor.classList.add('mouse-down');
		}
	}

	handleMouseUpOnMenu(e) {
		e.preventDefault()
		if (this.props.isMenuVisible && this.customMenuCursor.classList.contains('mouse-down')) {
			this.customMenuCursor.classList.remove('mouse-down');
		}
	}

	getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min;
	}

	handleMenuIconClick(e, dispatch, isMenuVisible, menuStatsFodActiveProjects) {
		e.persist();
		dispatch(toggleMenu(!isMenuVisible))
		const menuStatsNumber = menuStatsFodActiveProjects.length
		this.randomInfoNumber = this.getRandomInt(0, menuStatsNumber);
		this.setCursorOnMenu(e)
	}

	handleMouseMoveOnLink() {
		this.customMenuCursor.classList.add('bigger');
	}

	handleMouseOutOnLink() {
		this.customMenuCursor.classList.remove('bigger');
	}

	setPageWidthOnChrome(isMenuVisible) {
		if (typeof navigator !== "undefined") {
			const chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
			const bodyEl = document.getElementsByTagName("body")[0]
			if (chromeAgent && isMenuVisible) {
				bodyEl.style.width = 'calc(100% - 17px)';
			} else {
				bodyEl.style.width = '100%';
			}
		}
	}

	render() {
		const {isMenuVisible, dispatch, menuData, activeSite, menuStats, menuGlobal, translations, entries, pageContext, socialMediaData, logo, subheaderComponents, hasSubmenu, menuType} = this.props;
		const FloatingMenuData = (subheaderComponents) || [];
		const {isMenuStickyVisible, isMenuInStickyMode, isBtnStickyVisible, isOldBrowserEdge} = this.state
		const logoMenuVisibleUrl = (logo && logo.logoGranatowe && logo.logoGranatowe[0] && logo.logoGranatowe[0].url) || ""
		const logoStickyMenuUrl = (logo && logo.logoPrzyklejaneMenu && logo.logoPrzyklejaneMenu[0] && logo.logoPrzyklejaneMenu[0].url) || ""
		const logoSlug = (logo && logo.logoLink && logo.logoLink[0] && logo.logoLink[0].slug) || ""
		const isMenuBlue = menuType === 'granatowe'
		let menuStatsFodActiveProjects = []

		menuStats && menuStats.forEach((item) => {
			if (item.aktywny) {
				menuStatsFodActiveProjects.push(item);
			}
		})

		const hideMenu = () => {
			dispatch(toggleMenu(false))
		}

		this.setPageWidthOnChrome(isMenuVisible);

		const FloatingMenuDataArray = [];
		FloatingMenuData.forEach(item => {
			FloatingMenuDataArray.push({
				"kotwica": (item.modulNazwaSekcji && item.modulNazwaSekcji[0] && item.modulNazwaSekcji[0].kotwica) || "",
				"slug": (item.slug) || "",
				"nazwaSekcji": (item.modulNazwaSekcji && item.modulNazwaSekcji[0] && item.modulNazwaSekcji[0].tekst) || "",
			})
		});

		const sectionNameItemsKotwica = FloatingMenuDataArray.map(item => item.kotwica);
		const isFloatingMenuShown = (FloatingMenuDataArray.length);

		return (
			<MenuWrapperForCursor className={isMenuVisible ? 'cursor-hidden' : ''}
								  onMouseMove={!isMobile ? this.handleMouseMove.bind(this) : null}
								  onMouseDown={!isMobile ? this.handleMouseDownOnMenu.bind(this) : null}
								  onMouseUp={!isMobile ? this.handleMouseUpOnMenu.bind(this) : null}>
				<TopMenu className={(isMenuInStickyMode || isBtnStickyVisible) ? 'hide' : ''}>
					<IconMenu isMenuBlue={isMenuBlue} displayOnMobile={false} firstOtherLangPathArray={entries}
							  pageContext={pageContext}
							  socialMediaData={socialMediaData} menuGlobal={menuGlobal} activeSite={activeSite}
							  hideMenu={hideMenu}
							  handleMouseMoveOnLink={this.handleMouseMoveOnLink.bind(this)}
							  handleMouseOutOnLink={this.handleMouseOutOnLink.bind(this)}/>
					<MenuIconWithText
						onClick={(e) => this.handleMenuIconClick(e, dispatch, isMenuVisible, menuStatsFodActiveProjects)}
						onMouseMove={!isMobile ? this.handleMouseMoveOnLink.bind(this) : null}
						onMouseOut={!isMobile ? this.handleMouseOutOnLink.bind(this) : null}
						className={isMenuVisible ? 'menu-opened' : 'menu-closed'}>
						<MenuText className={isMenuBlue ? 'is-blue' : ''} id="menu-text">Menu</MenuText>
						<MenuIcon
							className={(isMenuBlue && isMenuVisible) ? "is-blue menu-opened" : isMenuVisible ? "menu-opened" : isMenuBlue ? "is-blue" : ""}>
							<span/>
							<span/>
							<span/>
						</MenuIcon>
					</MenuIconWithText>
				</TopMenu>
				<StickyMenuWithBgWrapper key="sticky-menu" className={isMenuStickyVisible ? "menu-visible" : ""}>
					<StickyMenuBg className={isMenuVisible ? "menu-opened" : "menu-closed"}/>
					<StickyMenuWrapper>
						<Link to={`/${getSlug(logoSlug, activeSite)}`}>
							<LogoClickableWrapper className={isMenuVisible ? 'hidden' : 'visible'}
												  onClick={hideMenu}>
								<StickyLogoStyled isMenuVisible={isMenuVisible} src={logoStickyMenuUrl}/>
							</LogoClickableWrapper>
						</Link>
						<IconMenu displayOnMobile={false} firstOtherLangPathArray={entries}
								  pageContext={pageContext}
								  socialMediaData={socialMediaData} isMenuStickyVisible={isMenuStickyVisible}
								  menuGlobal={menuGlobal} activeSite={activeSite} hideMenu={hideMenu}
								  handleMouseMoveOnLink={this.handleMouseMoveOnLink.bind(this)}
								  handleMouseOutOnLink={this.handleMouseOutOnLink.bind(this)}/>
						<MenuIconWithText className='is-sticky'
										  onClick={(e) => this.handleMenuIconClick(e, dispatch, isMenuVisible, menuStatsFodActiveProjects)}
										  onMouseMove={!isMobile ? this.handleMouseMoveOnLink.bind(this) : null}
										  onMouseOut={!isMobile ? this.handleMouseOutOnLink.bind(this) : null}>
							<MenuText className='is-blue' id="menu-text">Menu</MenuText>
							<MenuIcon className={isMenuVisible ? "is-blue menu-opened" : "is-blue"}>
								<span/>
								<span/>
								<span/>
							</MenuIcon>
						</MenuIconWithText>
					</StickyMenuWrapper>
					{hasSubmenu && !isOldBrowserEdge && !isMenuVisible && isFloatingMenuShown && <FloatingMenuWrapper>
						<Scrollspy items={sectionNameItemsKotwica} currentClassName="is-current" offset={141}>
							{FloatingMenuDataArray && FloatingMenuDataArray.map((item, key) => {
								const anchor = item.kotwica;
								return (
									<li key={key} onClick={() => scrollToSectionName(anchor)}>
										<MenuItem>{item.nazwaSekcji}</MenuItem>
									</li>
								)
							})}
						</Scrollspy>
					</FloatingMenuWrapper>}
				</StickyMenuWithBgWrapper>
				{<StickyBtnMenu className={isBtnStickyVisible ? "menu-visible" : ""} key="sticky-btn-menu">
					<IconMenu isMenuBlue={isMenuBlue} displayOnMobile={false} firstOtherLangPathArray={entries}
							  pageContext={pageContext}
							  socialMediaData={socialMediaData} menuGlobal={menuGlobal} activeSite={activeSite}
							  hideMenu={hideMenu} isBtnStickyVisible={isBtnStickyVisible}
							  handleMouseMoveOnLink={this.handleMouseMoveOnLink.bind(this)}
							  handleMouseOutOnLink={this.handleMouseOutOnLink.bind(this)}/>
				</StickyBtnMenu>}

				<PoseGroup>
					{isMenuVisible &&
					<StyledMenuWrapper key="menu">
						<LogoWrapper onMouseMove={!isMobile ? this.handleMouseMoveOnLink.bind(this) : null}
									 onMouseOut={!isMobile ? this.handleMouseOutOnLink.bind(this) : null}>
							<Link to={`/${getSlug(logoSlug, activeSite)}`}>
								<LogoStyledMenu onClick={hideMenu} src={logoMenuVisibleUrl}/>
							</Link>
						</LogoWrapper>
						<Nav id="menu" activeSite={activeSite} menuData={menuData} menuGlobal={menuGlobal}
							 hideMenu={hideMenu} firstOtherLangPathArray={entries} pageContext={pageContext}
							 socialMediaData={socialMediaData} isMenuStickyVisible={isMenuStickyVisible}
							 translations={translations} menuStatsFodActiveProjects={menuStatsFodActiveProjects}
							 InfoCard randomInfoNumber={this.randomInfoNumber}
							 customMenuCursor={this.customMenuCursor}/>
					</StyledMenuWrapper>
					}
				</PoseGroup>
				<CustomCursor id="custom-menu-cursor"
							  className={(isMenuVisible && !isMobile) ? 'bigger' : 'hidden'}>
				</CustomCursor>

			</MenuWrapperForCursor>
		)
	}
}

export default connect(state => ({
	isMenuVisible: state.app.isMenuVisible,
	linkToIdFromAnotherPage: state.app.linkToIdFromAnotherPage
}), null)(Menu)
