const initialState = {
	lang: "pl",
	isMenuVisible: false,
	linkToIdFromAnotherPage: false,
	mobileActiveProject: '',
	differentAmountFromCalculator: 0
}

const TOGGLE_MENU = "TOGGLE_MENU"
const CHANGE_LANG = "CHANGE_LANG"
const LINK_TO_ID= "LINK_TO_ID"
const SET_MOBILE_ACTIVE_PROJECT= "SET_MOBILE_ACTIVE_PROJECT"
const SET_DIFFERENT_VALUE_FROM_CALCULATOR= " SET_DIFFERENT_VALUE_FROM_CALCULATOR"

export const toggleMenu = isMenuVisible => ({
	type: TOGGLE_MENU, isMenuVisible,
})

export const changeLang = lang => ({
	type: CHANGE_LANG, lang,
})

export const changeLinkToIdFromAnotherPage = linkToIdFromAnotherPage => ({
	type: LINK_TO_ID, linkToIdFromAnotherPage,
})

export const setMobileActiveProject = mobileActiveProject => ({
	type: SET_MOBILE_ACTIVE_PROJECT, mobileActiveProject,
})

export const setDifferentAmountFromCalculator = differentAmountFromCalculator => ({
	type: SET_DIFFERENT_VALUE_FROM_CALCULATOR, differentAmountFromCalculator,
})

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_MENU:
			return { ...state, isMenuVisible: action.isMenuVisible }
		case CHANGE_LANG:
			return { ...state, lang: action.lang }
		case LINK_TO_ID:
			return { ...state, linkToIdFromAnotherPage: action.linkToIdFromAnotherPage }
		case SET_MOBILE_ACTIVE_PROJECT:
			return { ...state, mobileActiveProject: action.mobileActiveProject }
		case SET_DIFFERENT_VALUE_FROM_CALCULATOR:
			return { ...state, differentAmountFromCalculator: action.differentAmountFromCalculator }
		default:
			return state
	}
};
