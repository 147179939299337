import BasicButton from "./BasicButton";
import ButtonWithText from "./ButtonWithText";
import React from "react";
import TransparentBgBtn from "./ButtonTransparentBg";

const Button = (props) => {

	const {btnType} = props


	let button;

	if (btnType === 'pomarańczowo-biały') {
		button = <BasicButton {...props} btnClassName={'white'}/>;
	} else if (btnType === 'przeźroczysty') {
		button = <TransparentBgBtn {...props}/>;
	} else if (btnType === 'przeźroczysty z białym napisem') {
		button = <TransparentBgBtn {...props} btnClassName={'white-text'}/>;
	}else if (btnType === 'pomarańczowy') {
		button = <BasicButton {...props}/>;
	} else if (btnType === 'biały z serduszkiem') {
		button = <ButtonWithText {...props} btnClassName={'white'}/>;
	} else if (btnType === 'pomarańczowy z serduszkiem') {
		button = <ButtonWithText {...props} />;
	} else {
		button = <BasicButton {...props} btnClassName={'white'}/>;
	}

	return (
		<>
			{button}
		</>
	);
}

export default Button
