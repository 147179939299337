import React from 'react';
import styled from 'styled-components';
import ChangeLanguage from '../changeLanguage/ChangeLanguage';
import ButtonWithText from '../common/ButtonWithText';
import { connect } from 'react-redux';
import getSlug from '../../utils/getSlug';
import { isMobile } from 'react-device-detect';
import { fbqDonateBtnClicked } from '../../utils/fbqDonateBtnClicked';

const IconMenuWrapper = styled.div`
	display: none;
	margin-top: 35px;

	&.is-sticky {
		position: relative;
		top: 17px;
	}

	@media (min-width: 768px) {
		display: flex;
		justify-content: flex-end;
		flex-direction: row;
		align-items: center;
		margin: 0;
		&.is-sticky {
			top: 13px;
		}
	}
	@media (min-width: 1280px) {
		margin-right: ${props => (props.isBtnStickyVisible ? '70px' : '140px')};
		&.is-sticky {
			top: 24px;
		}
	}
	@media (min-width: 1366px) {
		margin-right: ${props => (props.isBtnStickyVisible ? '60px' : '130px')};
	}
	@media (min-width: 1920px) {
		margin-right: ${props => (props.isBtnStickyVisible ? '104px' : '190px')};

		&.is-sticky {
			position: relative;
			top: 17px;
		}
	}

	&.mobile {
		display: flex;

		@media (min-width: 768px) {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			margin: 30px 0 0;
		}
		@media (min-width: 1280px) {
			display: none;
		}
	}
`;

export const Icon = styled.a`
	background-image: url(${props => props.back});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	padding: 0;
	font-size: 18px;
	color: ${({ theme }) => theme.colors.pmmWhite};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	line-height: ${({ theme }) => theme.lineHeight.line4};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: visible;
	transition: transform 0.15s ease-in-out;
	width: 41px;
	height: 41px;
	margin-right: 11px;

	&.orange-hover {
		&:hover {
			background-image: url(${props => props.backOrange});
			transform: scale(1.113);
		}
	}
	&.orange-hover.edge {
		&:hover {
			background-image: url(${props => props.backOrange});
			transform: scale(1);
		}
	}

	&.menu-visible {
		cursor: none;
		&:hover {
			transform: scale(1.33);
		}
	}

	&.footer {
		border-radius: unset;
	}

	@media (min-width: 1280px) {
		width: 26px;
		height: 26px;
		margin-right: 7px;
	}
	@media (min-width: 1920px) {
		width: 34px;
		height: 34px;
		margin-right: 9px;
		&.edge {
			width: 33px;
		}
	}
`;

const BtnWrapper = styled.div`
	display: none;

	@media (min-width: 768px) {
		display: flex;
		margin-right: 140px;
	}
	@media (min-width: 1280px) {
		margin-right: 20px;
	}
	@media (min-width: 1366px) {
		margin-right: 20px;
	}
	@media (min-width: 1920px) {
		margin-right: 20px;
	}

	&.mobile {
		@media (min-width: 768px) {
			display: none;
		}
	}
	&.menu-opened {
		button {
			cursor: none;
		}
	}
`;

const IconsWrapper = styled.div`
	display: flex;

	@media (min-width: 768px) {
		display: none;
	}
	@media (min-width: 1280px) {
		display: flex;
	}
	&.mobile {
		@media (min-width: 768px) {
			display: flex;
		}
	}
`;

const IconWrapperForBgPreload = styled.div`
	// to preload image on hover - fixes flash for first hover
	background: url(${props => props.backOrange}) no-repeat -9999px -9999px;
`;

const BtnStickyTextAndIconWrapper = styled.div`
	display: flex;
`;

const BtnStickyIcon = styled.img``;

const BtnStickyText = styled.h6`
	margin-left: 10px;
	color: ${({ theme }) => theme.colors.pmmGray};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

class IconMenu extends React.Component {
	state = {
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== 'undefined') {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({ isOldBrowserEdge: isOldBrowser });
		}
	}

	render() {
		const {
			firstOtherLangPathArray,
			pageContext,
			socialMediaData,
			isMenuVisible,
			isMenuStickyVisible,
			menuGlobal,
			activeSite,
			hideMenu,
			displayOnMobile,
			isMenuBlue,
			isBtnStickyVisible,
			handleMouseMoveOnLink,
			handleMouseOutOnLink,
		} = this.props;
		const socialMedia = (socialMediaData && socialMediaData[0] && socialMediaData[0].socialMedia) || [];
		const btnData =
			(menuGlobal && menuGlobal[0] && menuGlobal[0].menuPrzyciskMenuPoziome && menuGlobal[0].menuPrzyciskMenuPoziome) || '';
		const donateBtnClassName = pageContext.remoteId === 1371 ? 'active' : '';
		const actionBtnClassName = pageContext.remoteId === 10533 ? 'white-menu-button active' : 'white-menu-button';

		let socialMediaArray = [];

		const horizontalMenuText = (menuGlobal && menuGlobal[0] && menuGlobal[0].menuNapisMenuPoziome) || '';
		const horizontalMenuIcon =
			(menuGlobal &&
				menuGlobal[0] &&
				menuGlobal[0].menuIkonaMenuPoziome &&
				menuGlobal[0].menuIkonaMenuPoziome[0] &&
				menuGlobal[0].menuIkonaMenuPoziome[0].url) ||
			'';

		socialMedia.forEach(icon => {
			socialMediaArray.push({
				logo: (icon.socialMediaLogo && icon.socialMediaLogo[0] && icon.socialMediaLogo[0].url) || '',
				logoOrange: (icon.socialMediaLogoOrange && icon.socialMediaLogoOrange[0] && icon.socialMediaLogoOrange[0].url) || '',
				logoStickyMenu:
					(icon.socialMediaLogoStickyMenu && icon.socialMediaLogoStickyMenu[0] && icon.socialMediaLogoStickyMenu[0].url) || '',
				link: icon.socialMediaLink || '',
			});
		});

		const iconClassName = this.state.isOldBrowserEdge ? ' edge' : '';
		return (
			<IconMenuWrapper
				className={isMenuStickyVisible || isBtnStickyVisible ? 'is-sticky' : displayOnMobile ? 'mobile' : ''}
				isBtnStickyVisible={isBtnStickyVisible}
			>
				<BtnWrapper
					onClick={hideMenu}
					className={displayOnMobile ? 'mobile' : isMenuVisible ? 'menu-opened' : ''}
					onMouseMove={!isMobile ? () => handleMouseMoveOnLink() : null}
					onMouseOut={!isMobile ? () => handleMouseOutOnLink() : null}
				>
					{btnData &&
						btnData.map(item => (
							<ButtonWithText
								onClick={() => fbqDonateBtnClicked('kliknieto z menu')}
								textBtn={item.przyciskTekst}
								isMenuVisible={isMenuVisible}
								to={`/${getSlug(item.przyciskWpis[0].slug, activeSite)}`}
								href={item.przyciskLink}
								key={item.przyciskWpis[0].slug}
								btnClassName={item.przyciskWpis[0].slug === 'chce-pomoc' ? donateBtnClassName : actionBtnClassName}
							/>
						))}
				</BtnWrapper>
				{isBtnStickyVisible && (
					<BtnStickyTextAndIconWrapper>
						<BtnStickyIcon src={horizontalMenuIcon} />
						<BtnStickyText>{horizontalMenuText}</BtnStickyText>
					</BtnStickyTextAndIconWrapper>
				)}

				{!isBtnStickyVisible && (
					<IconsWrapper
						className={displayOnMobile ? 'mobile' : ''}
						onMouseMove={!isMobile ? () => handleMouseMoveOnLink() : null}
						onMouseOut={!isMobile ? () => handleMouseOutOnLink() : null}
					>
						{socialMediaArray.map((icon, key) => (
							<React.Fragment key={key}>
								<IconWrapperForBgPreload backOrange={icon.logoOrange}>
									{!isMenuStickyVisible && !isMenuBlue && (
										<Icon
											className={isMenuVisible ? 'menu-visible' : 'menu-not-visible orange-hover' + iconClassName}
											href={icon.link}
											target="_blank"
											rel="noopener noreferrer"
											back={icon.logo}
											backOrange={icon.logoOrange}
										/>
									)}
									{(isMenuStickyVisible || isMenuBlue) && (
										<Icon
											className={isMenuVisible ? 'menu-visible' : 'menu-not-visible orange-hover' + iconClassName}
											href={icon.link}
											target="_blank"
											rel="noopener noreferrer"
											back={icon.logoStickyMenu}
											backOrange={icon.logoOrange}
										/>
									)}
								</IconWrapperForBgPreload>
							</React.Fragment>
						))}
						<ChangeLanguage
							firstOtherLangPathArray={firstOtherLangPathArray}
							pageContext={pageContext}
							isMenuStickyVisible={isMenuStickyVisible}
							displayForAllSizes={false}
							isMenuBlue={isMenuBlue}
						/>
					</IconsWrapper>
				)}
			</IconMenuWrapper>
		);
	}
}

export default connect(
	state => ({
		isMenuVisible: state.app.isMenuVisible,
	}),
	null,
)(IconMenu);
