export async function sendData(data) {
	const url = process.env.GATSBY_CONTACT_FORM_URL;

	const rawData = fetch( url, {
		method: "POST",
		headers: {
			"Accept": "application/json"
		},
		body: data,
	});

	return rawData
}

