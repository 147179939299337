import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"
import {isMobile} from 'react-device-detect';

const BasicButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
`

const BasicButtonStyled = styled.button`
	cursor: pointer;
	display: block;
	max-height: 45px;
	vertical-align: middle;
	background-color: transparent;
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-size: ${({theme}) => theme.fontSize.f4};
	line-height: ${({theme}) => theme.lineHeight.line13};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	color: ${({theme}) => theme.colors.pmmWhite};
	border-radius: 50px;
	padding: 13px 30px;
	border: none;
	text-transform: uppercase;
	transition: margin .25s ease-out, padding .25s ease-out;
	position: relative;
	white-space: nowrap;
	z-index: 1;
	text-decoration: none;
	&.smaller-padding {
		padding: 13px 15px;
		@media(min-width: 768px) {
			padding: 10px 20px;
		}
		@media(min-width: 1920px) {
			padding: 13px 30px;
		}
	}
	
	&:before {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		top:0;
		left: 0;
		z-index: -1;
		border-radius: 50px;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		transition: left .25s ease-out, width .25s ease-out;
	}
	&.hover {
		&:hover {
			&:before {
				content: '';
				position: absolute;
				height: 100%;
				width: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "100%" : "calc(100% + 20px)"};
				top: 0;
				left: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "0" : "-10px"};
				z-index: -1;
				border-radius: 50px;
				background-color: ${({theme}) => theme.colors.pmmOrange};
			}
		}
		 &.animate {
			&:before {
				content: '';
				position: absolute;
				height: 100%;
				width: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "100%" : "calc(100% + 20px)"};
				top: 0;
				left: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "0" : "-10px"};
				z-index: -1;
				border-radius: 50px;
				background-color: ${({theme}) => theme.colors.pmmOrange};
			}
		}
		&.white {
			&:hover {
				color: ${({theme}) => theme.colors.pmmOrange};
				&:before {
					background-color: ${({theme}) => theme.colors.pmmWhite};
					border: 2px solid ${({theme}) => theme.colors.pmmOrange};
				}
			}
		}
	}
	
	@media(min-width: 768px){
		font-size: ${({theme}) => theme.fontSize.f10};
		line-height: ${({theme}) => theme.lineHeight.line10};
		max-height: 34px;
		padding: 10px 20px;
	}
	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f4};
		line-height: ${({theme}) => theme.lineHeight.line13};
		max-height: 45px;
		padding: 13px 30px;
	}
`;

const BasicButton = (props) => {
	const { href, to, btnClassName, textBtn, onClick, onMouseOver, onMouseOut, form, type, smallerPaddingClass, isOldBrowserEdge} = props
	const btnClassNameIfExist = btnClassName ? btnClassName : ''
	return (
		<BasicButtonWrapper>
			{href && <BasicButtonStyled href={href} as={"a"} className={isMobile ? `${btnClassNameIfExist} h6` : `${btnClassNameIfExist} h6 hover`} target="_blank" rel="noopener noreferrer" onClick={onClick} onFocus={onMouseOver} onBlur={onMouseOut} onMouseOver={onMouseOver} onMouseOut={onMouseOut} form={form} type={type} isOldBrowserEdge={isOldBrowserEdge}>{textBtn}</BasicButtonStyled>}
			{!href && to && to !== '/'  && <BasicButtonStyled as={Link} to={to} className={isMobile ? `${btnClassNameIfExist} h6` : `${btnClassNameIfExist} h6 hover`} onFocus={onMouseOver} onBlur={onMouseOut} onMouseOver={onMouseOver} onClick={onClick} onMouseOut={onMouseOut} form={form} type={type} isOldBrowserEdge={isOldBrowserEdge}>{textBtn}</BasicButtonStyled>}
			{!href && !to && <BasicButtonStyled className={isMobile ? `${btnClassNameIfExist} ${smallerPaddingClass} h6` : `${btnClassNameIfExist} ${smallerPaddingClass} h6 hover`} onClick={onClick} onFocus={onMouseOver} onBlur={onMouseOut} onMouseOver={onMouseOver} onMouseOut={onMouseOut} form={form} type={type} isOldBrowserEdge={isOldBrowserEdge}>{textBtn}</BasicButtonStyled>}
		</BasicButtonWrapper>
	)
}

export default BasicButton
