export const theme = {
	colors: {
		pmmBlack: '#000',
		pmmBeige: '#DECDC5',
		pmmBrown: '#382119',
		pmmLightOrange: '#FEC4A6',
		pmmWhite: '#fff',
		pmmOrange: '#FF4124',
		pmmViolet: '#36235E',
		pmmDarkBlue: '#261447',
		pmmDarkViolet: '#17092E',
		pmmInputBorderDark: '#260E49',
		pmmInputBorderOrange: '#FF3F0F',
		pmmPlaceHolderGrey: '#707070',
		pmmModalDarkBackground: '#10061F',
		pmmInputErrorOrange: '#FDD3CD',
		pmmLightViolet: '#432E70',
		pmmTextOrange: '#FD4123',
		pmmFooterViolet: '#534867',
		pmmWhiteAlpha: 'rgba(255,255,255,.25)',
		transparent: 'transparent',
		pmmDarkestBlue: '#251447',
		pmmGreyBackground: '#F2F1F2',
		pmmRed: 'red',
		pmmGray: '#D3D3D3',
		pmmOrangeBeige: '#F2E9E6',
	},

	fontFamily: {
		mainFont: '\'Silka\', sans-serif',
	},

	fontWeight: {
		thin: 100,

		light: 300,

		regular: 400,

		medium: 500,

		semiBold: 600,

		bold: 700,

		extraBold: 800,

		black: 900,

	},

	lineHeight: {
		line1: '28px',
		line2: '53px',
		line3: '24px',
		line4: '21px',
		line5: '50px',
		line6: '34px',
		line7: '40px',
		line8: '36px',
		line9: '64px',
		line10: '14px',
		line11: '11px',
		line12: '16px',
		line13: '19px',
		line14: '25px',
		line15: '30px',
		line16: '18px',
		line17: '49px',
		line18: '32px',
		line19: '60px',
		line20: '26px',
		line21: '20px',
		line22: '22px',
		line33: '38px',
		line34: '55px',
		line35: '48px',
		line36: '44px',
		line37: '54px',
		line38: '63px',
		line40: '29px',
		line41: '105px',
		//////// line heights connected with tags //////////////
		h1_1920: '84px',
		h1_1366: '64px',
		h1_1280: '62px',
		h1_768: '59px',
		h1_375: '34px',
		h1_360: '34px',
		h2_1920: '75px',
		h2_1366: '60px',
		h2_1280: '58px',
		h2_768: '50px',
		h2_375: '36px',
		h2_360: '36px',
		h3_1920: '48px',
		h3_1366: '36px',
		h3_1280: '34px',
		h3_768: '34px',
		h3_375: '32px',
		h3_360: '32px',
		h4_1920: '44px',
		h4_1366: '36px',
		h4_1280: '34px',
		h4_768: '32px',
		h4_375: '26px',
		h4_360: '26px',
		h5_1920: '21px',
		h5_1366: '17px',
		h5_1280: '17px',
		h5_768: '17px',
		h5_375: '17px',
		h5_360: '17px',
		h6_1920: '19px',
		h6_1366: '15px',
		h6_1280: '15px',
		h6_768: '15px',
		h6_375: '15px',
		h6_360: '17px',
		p_1920: '38px',
		p_1366: '34px',
		p_1280: '33px',
		p_768: '34px',
		p_375: '32px',
		p_360: '32px',
		h7_1920: '32px',
		h7_1366: '28px',
		h7_1280: '27px',
		h7_768: '26px',
		h7_375: '26px',
		h7_360: '26px',
		h8_1920: '17px',
		h8_1366: '15px',
		h8_1280: '14px',
		h8_768: '15px',
		h8_375: '14px',
		h8_360: '14px',
	},

	headers: {
		h1: '80px',
	},

	paragraphs: {
		p1: '20px',
	},

	fontSize: {
		f1: '140px',
		f2: '40px',
		f3: '18px',
		f4: '16px',
		f5: '30px',
		f6: '20px',
		f7: '60px',
		f8: '50px',
		f9: '15px',
		f10: '12px',
		f11: '10px',
		f12: '14px',
		f13: '28px',
		f14: '13px',
		f15: '28px',
		f16: '25px',
		f17: '22px',
		f18: '24px',
		f19: '9px',
		//////// fonts' sizes connected with tags //////////////
		h1_1920: '80px',
		h1_1366: '60px',
		h1_1280: '58px',
		h1_768: '55px',
		h1_375: '30px',
		h1_360: '30px',
		h2_1920: '50px',
		h2_1366: '40px',
		h2_1280: '38px',
		h2_768: '30px',
		h2_375: '28px',
		h2_360: '28px',
		h3_1920: '40px',
		h3_1366: '30px',
		h3_1280: '28px',
		h3_768: '28px',
		h3_425: '26px',
		h3_375: '25px',
		h3_360: '25px',
		h4_1920: '30px',
		h4_1366: '22px',
		h4_1280: '20px',
		h4_768: '18px',
		h4_375: '16px',
		h4_360: '16px',
		h5_1920: '18px',
		h5_1366: '14px',
		h5_1280: '14px',
		h5_768: '12px',
		h5_375: '12px',
		h5_360: '12px',
		h6_1920: '16px',
		h6_1366: '12px',
		h6_1280: '12px',
		h6_768: '12px',
		h6_375: '14px',
		h6_360: '14px',
		p_1920: '20px',
		p_1366: '16px',
		p_1280: '15px',
		p_768: '15px',
		p_375: '14px',
		p_360: '14px',
		h7_1920: '24px',
		h7_1366: '20px',
		h7_1280: '19px',
		h7_768: '18px',
		h7_375: '18px',
		h7_360: '18px',
		h8_1920: '14px',
		h8_1366: '12px',
		h8_1280: '11px',
		h8_768: '12px',
		h8_375: '11px',
		h8_360: '11px',
	},

	letterSpacing: {
		h5_360_medium: '0.38px',
		h5_360_light: '0.01px',
		h5_360_bold: '1.46px',
		h5_375_medium: '0.38px',
		h5_375_light: '0.01px',
		h5_375_bold: '1.46px',
		h5_768_medium: '0.38px',
		h5_768_light: '0.01px',
		h5_768_bold: '1.46px',
		h5_1280_medium: '0.44px',
		h5_1280_light: '0.01px',
		h5_1280_bold: '1.7px',
		h5_1366_medium: '0.44px',
		h5_1366_light: '0.01px',
		h5_1366_bold: '1.7px',
		h5_1920_medium: '0.44px',
		h5_1920_light: '0.015px',
		h5_1920_bold: '2.16px',
		h8_1920_black: '1.4px',
		h8_1366_black: '1.2px',
		h8_1280_black: '1.1px',
		h8_768_black: '1.2px',
		h8_375_black: '1.1px',
		h8_360_black: '1.1px',

		h5_360_semi_bold: '-0.72px',
		h5_375_semi_bold: '-0.72px',
		h5_768_semi_bold: '-0.72px',
		h5_1280_semi_bold: '-0.72px',
		h5_1366_semi_bold: '-0.84px',
		h5_1920_semi_bold: '-1.08px',
		h3_360_semi_bold: '-0.78px',
		h3_375_semi_bold: '-0.78px',
		h3_768_semi_bold: '-0.84px',
		h3_1280_semi_bold: '-0.84px',
		h3_1366_semi_bold: '-0.9x',
		h3_1920_semi_bold: '-1.2px',
	},

}

