import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import {NoBackgroundBtn} from "../common/ButtonsRandom"
import getSlug from "../../utils/getSlug"
import {Link} from "gatsby"

const InfoCardWrapper = styled.div`
	display: none;
	
	@media(min-width: 768px){
		display: flex;
		margin-top: 50px;
		position: relative;
		width: 100%;
	}
	@media(min-width: 1280px){
		margin-top: 0;
		position: unset;
	}
`
const InfoCardContent = styled.div`
	@media(min-width: 768px){
		position: absolute;
		display: flex;
		left:-70px;
		right: 20px;
		margin-bottom: 20px;
		z-index: 1000;
		border: 2px solid ${({theme}) => theme.colors.pmmWhite};
		padding: 45px 40px 30px 80px;
		flex-direction: column;
		width: auto;
		height: 310px;
	}
	@media(min-width: 1280px){
		height: 45%;
		width: 30%;
		left: unset;
		display: flex;
		position: absolute;
		z-index: 1000;
		bottom: 20px;
		margin-bottom: 0;
		right: 20px;
		padding: 30px 30px 20px;
		flex-direction: column;
	}
	@media(min-width: 1366px){
		right: 25px;
	}
	@media(min-width: 1920px){
		height: 48%;
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
		font-size: ${({theme}) => theme.fontSize.f2};
		line-height:  ${({theme}) => theme.lineHeight.line2};
		padding: 50px 45px 30px;
	}
	@media(min-width: 2300px){
		padding: 60px 55px 40px;
	}
`

const Header = styled.div`
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	font-size: ${({theme}) => theme.fontSize.f5};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	line-height: ${({theme}) => theme.lineHeight.line5};
	text-transform: uppercase;
	
	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f2};
		line-height: ${({theme}) => theme.lineHeight.line36};
	}
	@media(min-width: 2300px){
		font-size: ${({theme}) => theme.fontSize.f8};
	}
`

const StatsInfo = styled.div`
	p {
	font-size: ${({theme}) => theme.fontSize.f6};
	line-height: ${({theme}) => theme.lineHeight.line6};
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	
	@media(min-width: 768px){
		margin-top: 20px;
	}
	@media(min-width: 1920px){
		margin-top: 35px;
		font-size: ${({theme}) => theme.fontSize.f5};
		line-height: ${({theme}) => theme.lineHeight.line36};
	}
	@media(min-width: 2300px){
		margin-top: 45px;
		font-size: ${({theme}) => theme.fontSize.f2};
		line-height: ${({theme}) => theme.lineHeight.line37};
	}
}
`

const ButtonWrapper = styled.div`
	margin-top: 35px;
	a {
		cursor: none !important;
	}

`

const BottomElement = styled.div`
	align-self: flex-end;
	margin-top: auto;
`

const BottomText = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-size: ${({theme}) => theme.fontSize.f14};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	text-transform: uppercase;
	position: relative;
	
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: -50px;
		width: 40px;
		height: 3px;
		background-color:  ${({theme}) => theme.colors.pmmWhite};
	}
	
	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f4};
		line-height:  ${({theme}) => theme.lineHeight.line13};
		
		&:before {
			left: -80px;
			width: 60px;
			bottom: 3px;
		}
	}
	@media(min-width: 2300px){
		font-size: ${({theme}) => theme.fontSize.f17};
		line-height:  ${({theme}) => theme.lineHeight.line20};
		
		&:before {
			left: -100px;
			width: 80px;
			bottom: 3px;
		}
	}
`

class InfoCard extends React.Component {

	render() {
		const {menuStatsFodActiveProjects, translations, activeSite, hideMenu, randomInfoNumber, handleMouseMoveOnLink, handleMouseOutOnLink} = this.props;
		const menuStatsData = (menuStatsFodActiveProjects && menuStatsFodActiveProjects[randomInfoNumber]) || ""
		const title = menuStatsData.title || ""
		const statsInfo = (menuStatsData.statystykiMenu && menuStatsData.statystykiMenu[0] && menuStatsData.statystykiMenu[0].opis) || ""
		const statsTextBtn = (menuStatsData.statystykiMenu && menuStatsData.statystykiMenu[0] && menuStatsData.statystykiMenu[0].przyciskTekst) || ""
		const statsBtnSlug = getSlug(menuStatsData.slug, activeSite)
		const bottomText = (translations && translations[0] && translations[0].tlumaczenieCzyWieszZe) || ""

		return (
			<InfoCardWrapper>
				<InfoCardContent>
					<Header>{title}</Header>
					<StatsInfo dangerouslySetInnerHTML={getHtmlFromRedactorField(statsInfo)}/>
					<ButtonWrapper >
						<NoBackgroundBtn as={Link} to={`/${statsBtnSlug}`}
										 onClick={hideMenu}  onMouseMove={() => handleMouseMoveOnLink()}
								  onMouseOut={() => handleMouseOutOnLink()}>{statsTextBtn}</NoBackgroundBtn>
					</ButtonWrapper>
					<BottomElement>
						<BottomText>{bottomText}</BottomText>
					</BottomElement>
				</InfoCardContent>
			</InfoCardWrapper>
		)
	}
}

export default InfoCard
