import React from 'react';
import styled from 'styled-components';
import BtnLogo from '../../icons/btn-logo.svg';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';

const BasicBtn = styled.button`
	text-decoration: none;
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: ${({ theme }) => theme.fontSize.f4};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	color: ${({ theme }) => theme.colors.pmmWhite};
	border: 2px solid ${({ theme }) => theme.colors.pmmOrange};
	border-radius: 60px;
	padding: 0 30px;
	margin: 0px 7.5px;
	text-transform: uppercase;
	transition: margin 0.25s ease-out, padding 0.25s ease-out;
	background-color: ${({ theme }) => theme.colors.pmmOrange};
	&.white-menu-button {
		background-color: ${({ theme }) => theme.colors.pmmWhite};
		color: ${({ theme }) => theme.colors.pmmOrange};
		&.dark {
			background-color: ${({ theme }) => theme.colors.pmmWhite};
		}
		div {
			padding: 10px 0;
			position: relative;
			&:before {
				background-size: 0px;
			}
		}
		&.hover {
			&.active,
			&:hover {
				padding: 0px 32.5px;
				margin: 0px 0px;
			}
		}
	}
	&.dark {
		background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	}

	&.white {
		background-color: ${({ theme }) => theme.colors.pmmWhite};
		color: ${({ theme }) => theme.colors.pmmOrange};
	}

	div {
		padding: 10px 0;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: -30px;
			width: 100%;
			height: 100%;
			background-size: 20px 25px;
			background-image: url(${BtnLogo});
			background-repeat: no-repeat;
			background-position: 0 center;
			opacity: 0;
			transition: opacity 0.2s linear;
		}
	}

	&.hover {
		&.active,
		&:hover {
			background-color: ${({ theme }) => theme.colors.pmmWhite};
			color: ${({ theme }) => theme.colors.pmmOrange};
			padding-left: 50px;
			margin-right: 5px;
			margin-left: -10px;

			div {
				&:before {
					transition: opacity 0.2s linear;
					opacity: 1;
				}
			}
		}
		&.white {
			&:hover {
				background-color: ${({ theme }) => theme.colors.pmmWhite};
				color: ${({ theme }) => theme.colors.pmmOrange};
			}
		}
	}

	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSize.f10};
		line-height: ${({ theme }) => theme.lineHeight.line10};
		padding: 0 25px;
	}

	@media (min-width: 1920px) {
		font-size: ${({ theme }) => theme.fontSize.f4};
		line-height: ${({ theme }) => theme.lineHeight.line13};
		div {
			padding: 10px 0;
		}
	}
`;

class ButtonWithText extends React.Component {
	render() {
		const { textBtn, isMenuVisible, to, href, btnClassName, onClick, onMouseOver, onMouseOut, type, form } = this.props;
		const btnClass = btnClassName ? btnClassName : '';
		const conditionalClassName =
			isMenuVisible && isMobile
				? `${btnClass} dark`
				: isMenuVisible
				? `${btnClass} dark hover`
				: isMobile
				? `${btnClass} `
				: `${btnClass} hover`;

		return (
			<>
				{href && (
					<BasicBtn
						href={href}
						as={'a'}
						className={conditionalClassName}
						onClick={onClick}
						onFocus={onMouseOver}
						onBlur={onMouseOut}
						onMouseOver={onMouseOver}
						onMouseOut={onMouseOut}
						form={form}
						type={type}
					>
						<div className="text">{textBtn}</div>
					</BasicBtn>
				)}
				{to && (
					<Link to={to}>
						<BasicBtn
							className={conditionalClassName}
							onClick={onClick}
							onFocus={onMouseOver}
							onBlur={onMouseOut}
							onMouseOver={onMouseOver}
							onMouseOut={onMouseOut}
							form={form}
							type={type}
						>
							<div className="text">{textBtn}</div>
						</BasicBtn>
					</Link>
				)}
				{!href && !to && (
					<BasicBtn
						className={conditionalClassName}
						onClick={onClick}
						onFocus={onMouseOver}
						onBlur={onMouseOut}
						onMouseOver={onMouseOver}
						onMouseOut={onMouseOut}
						form={form}
						type={type}
					>
						<div className="text">{textBtn}</div>
					</BasicBtn>
				)}
			</>
		);
	}
}

export default ButtonWithText;
