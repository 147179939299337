import React, { Component } from 'react';
import styled from 'styled-components';
import getSlug from '../../utils/getSlug';
import { Link } from 'gatsby';
import { Icon } from '../iconMenu/IconMenu';
import ChangeLanguage from '../changeLanguage/ChangeLanguage';
import CheckboxInfo from '../newsletter/CheckboxInfo';
import Popup from '../common/popup/Popup';
import { sendData } from '../../utils/sendEmailMethod';
import Button from '../common/ButtonsSet';
import WholeInfoProcessingPersonalData from '../common/WholeInfoProcessingPersonalData';
import { fbqDonateBtnClicked } from '../../utils/fbqDonateBtnClicked';
// import ButtonWithText from "../common/ButtonWithText";

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.pmmDarkViolet};

	@media (min-width: 768px) {
		padding: 80px 35px;
		position: relative;
	}
	@media (min-width: 1280px) {
		flex-direction: row;
		padding: 80px 50px;
		flex-wrap: wrap;
	}
	@media (min-width: 1366px) {
	}
	@media (min-width: 1920px) {
		padding: 80px 100px;
	}
`;

const FirstWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 60px;

	@media (min-width: 768px) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.pmmFooterViolet};
		padding-top: 0;
		justify-content: flex-start;
	}
	@media (min-width: 1280px) {
		width: 64%;
	}
	@media (min-width: 1366px) {
	}
	@media (min-width: 1920px) {
	}
`;

const InfoWrapper = styled.ul`
	display: none;
	padding-bottom: 50px;

	h5 {
		color: ${({ theme }) => theme.colors.pmmWhite};
		font-size: ${({ theme }) => theme.fontSize.f5};
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		line-height: ${({ theme }) => theme.lineHeight.line8};
		padding-bottom: 20px;
	}

	@media (min-width: 768px) {
		width: 60%;
		display: flex;
		flex-direction: column;

		h5 {
			padding-bottom: 80px;
		}
	}
	@media (min-width: 1280px) {
		padding-bottom: 80px;

		h5 {
			padding-bottom: 75px;
		}
	}
	@media (min-width: 1366px) {
	}
	@media (min-width: 1920px) {
		//width: 70%;
		width: 860px;
		padding-bottom: 130px;
		padding-right: 260px;
		h5 {
			font-size: ${({ theme }) => theme.fontSize.f2};
			line-height: ${({ theme }) => theme.lineHeight.line17};
		}
	}

	&.mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 80px;
		width: 100%;

		@media (min-width: 768px) {
			display: none;
		}
	}

	li {
		display: flex;
		color: ${({ theme }) => theme.colors.pmmTextOrange};
		padding: 10px 0;
		text-align: center;
		border-bottom: 1px solid ${({ theme }) => theme.colors.pmmWhiteAlpha};
		font-size: ${({ theme }) => theme.fontSize.f10};
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		line-height: ${({ theme }) => theme.lineHeight.line6};
		width: calc(100% - 30px);
		justify-content: center;

		span {
			color: ${({ theme }) => theme.colors.pmmWhite};
			margin-right: 5px;
		}

		&:last-of-type {
			border-bottom: none;
		}

		@media (min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.f12};
		}
		@media (min-width: 768px) {
			padding: 5px 0;
			text-align: left;
			width: 100%;
			justify-content: flex-start;
		}
		@media (min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.f3};
		}
	}
`;

const BtnWrapper = styled.div`
	margin-top: 40px;
`;
const NavListWrapper = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	li {
		padding-bottom: 25px;
		font-size: ${({ theme }) => theme.fontSize.f3};
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		line-height: ${({ theme }) => theme.lineHeight.line16};
	}

	@media (min-width: 768px) {
		width: 40%;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: 70px;
		margin-top: 115px;
		li {
			padding-bottom: 15px;
		}
	}
	@media (min-width: 1920px) {
		margin-left: 0;
		width: auto;
		li {
			font-size: ${({ theme }) => theme.fontSize.f6};
			line-height: ${({ theme }) => theme.lineHeight.line14};
		}
	}
`;

const SecondWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.colors.pmmFooterViolet};

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: flex-start;
	}
	@media (min-width: 1280px) {
		flex-direction: column;
		justify-content: flex-start;
		margin-top: 115px;
		padding-bottom: 70px;
		width: 36%;
	}
`;

const SocialMediaAndNewsletterWrapper = styled.div`
	margin: 0 10px;
	width: calc(100% - 20px);

	@media (min-width: 768px) {
		width: 60%;
	}
	@media (min-width: 1280px) {
		width: 100%;
		margin: 0;
	}
`;

const FooterSocialMediaWrapper = styled.div`
	display: flex;
	padding-top: 40px;
	justify-content: center;

	a {
		width: 34px;
		height: 34px;
		margin-right: 20px;

		&:last-of-type {
			margin-right: 0;
		}
	}
	@media (min-width: 768px) {
		justify-content: flex-start;
	}
	@media (min-width: 1280px) {
		padding-top: 0;
	}
`;

const FooterLink = styled(Link)`
	-webkit-tap-highlight-color: transparent;
	color: ${({ theme }) => theme.colors.pmmWhite};
	text-decoration: none;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	font-size: ${({ theme }) => theme.fontSize.f3};
	cursor: pointer;

	&:hover,
	&:focus {
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		outline: none;
	}
`;

const NewsletterWrapper = styled.div`
	margin: 45px 0 100px;
	height: 150px;
	width: 100%;

	@media (min-width: 768px) {
		margin: 45px 0 50px;
	}
`;

const NewsletterTitle = styled.div`
	color: ${({ theme }) => theme.colors.pmmWhite};
	font-weight: ${({ theme }) => theme.fontWeight.light};
	font-size: ${({ theme }) => theme.fontSize.f4};

	@media (min-width: 768px) {
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	}
	@media (min-width: 1280px) {
		font-size: ${({ theme }) => theme.fontSize.f12};
	}
	@media (min-width: 1920px) {
		font-size: ${({ theme }) => theme.fontSize.f4};
	}
`;

//TODO: change to one style for two newsletters
const InputAndButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	position: relative;
	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
	}
	@media (min-width: 1280px) {
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 30px;
	}
	form {
		display: flex;
		flex-direction: column;
		width: 100%;
		@media (min-width: 768px) {
			flex-direction: row;
		}
	}
`;

const InputWrapper = styled.span`
	position: relative;
	width: 100%;
	margin-right: 10px;
	margin-top: 30px;
	@media(min-width: 768px) {
		width: 64%;
	}
	@media(min-width: 1024px) {
		width: 73%;
	}
	@media(min-width: 1280px) {
	    width: 64%;
	    margin-top: 0;
		margin-right: 30px;
 	}
	@media(min-width: 1366px){
		width: 67%;	
		margin-top: 20px;
	}
	@media(min-width: 1600px){
		width: 65%;	
		margin-top: 20px;
	}
	@media(min-width: 1700px){
		width: 70%;
	}
	@media(min-width: 1920px){
		width: 67%;
	}
	&:after {
		content: '${({ customPlaceholder }) => customPlaceholder}';
		position: absolute;
		left: 17px;
		top: 18px;
		font-family: ${({ theme }) => theme.fontFamily.mainFont};
		font-weight: ${({ theme }) => theme.fontWeight.light};
		color: ${({ theme }) => theme.colors.pmmPlaceHolderGrey};
		font-size: 16px;
		cursor: pointer;
		transition: top .25s ease-in-out, font-size .25s ease-in-out;
		@media(min-width: 1920px){
			font-size: 18px;
			top: 24px;
		}
	}
	&.active::after {
		top: -25px;
		font-size: 14px;
	  	display: none;
		@media(min-width: 768px) {
			font-size: 15px;
          	display: block;
		}
    }  
`;

const StyledInput = styled.input`
	border: ${({ theme }) => '1px solid' + theme.colors.pmmInputBorderDark};
	background-color: ${({ theme }) => '2px solid' + theme.colors.pmmWhite};
	outline: none;
	display: block;
	padding: 10px 15px;
	width: 100%;
	position: relative;
	height: 55px;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	color: #707070;
	font-size: 18px;
	cursor: pointer;
	transition: border 0.25s ease-in-out, background-color 0.25s ease-in-out;
	box-shadow: none;

	@media (min-width: 1920px) {
		padding: 10px 15px;
		width: 100%;
		height: 70px;
	}
	&.active {
		border: ${({ theme }) => '1px solid' + theme.colors.pmmInputBorderOrange};
	}
	&.wrong-email {
		border: ${({ theme }) => '3px solid' + theme.colors.pmmInputBorderOrange};
		background-color: ${({ theme }) => theme.colors.pmmInputErrorOrange};
	}
`;

const IncorrectEmailInfo = styled.p`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	color: ${({ theme }) => theme.colors.pmmOrange};
	font-size: 15px;
	padding-left: 16px;
	position: absolute;
	top: -23px;
	display: ${({ showIncorrectMailInfo }) => (showIncorrectMailInfo === true ? 'flex' : 'none')};
	@media (min-width: 1280px) {
		font-size: 15px;
		padding-left: 16px;
		position: absolute;
		top: -23px;
	}
`;

const CheckboxInfoVisibilityWrapper = styled.div`
	display: block;
	color: ${({ theme }) => theme.colors.pmmWhite};

	@media (min-width: 768px) {
		display: none;
	}

	&.big-screen {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}
`;

const ButtonWrapper = styled.div`
	height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 35px;
	@media (min-width: 400px) {
		margin-top: 10px;
	}
	@media (min-width: 768px) {
		align-items: center;
		margin-top: 20px;
		justify-content: flex-start;
	}
	@media (min-width: 1280px) {
		align-items: flex-start;
		margin-top: 10px;
		flex-direction: row;
		justify-content: flex-start;
	}
	@media (min-width: 1366px) {
		flex-direction: column;
		justify-content: center;
	}
	@media (min-width: 1920px) {
		margin-top: 20px;
	}
`;

// end of newsletter styles

const LangWrapper = styled.div`
	padding-top: 35px;

	h6 {
		color: ${({ theme }) => theme.colors.pmmWhite};
		font-size: ${({ theme }) => theme.fontSize.f4};
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		line-height: ${({ theme }) => theme.lineHeight.line12};
	}

	@media (min-width: 768px) {
		width: 40%;
		margin-left: 70px;
		padding-top: 100px;
	}
	@media (min-width: 1280px) {
		padding: 10px 0 0;
		margin: 0;
	}
`;

const ChangeLanguageWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: center;

	@media (min-width: 768px) {
		justify-content: flex-start;
	}
`;
const ThirdWrapper = styled.div`
	display: flex;
	color: ${({ theme }) => theme.colors.pmmFooterViolet};
	font-size: ${({ theme }) => theme.fontSize.f10};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	margin-top: 30px;
	flex-direction: column;
	align-items: center;

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: flex-start;
		margin-top: 20px;
	}
	@media (min-width: 1280px) {
		width: 100%;
		flex-flow: row-reverse;
	}
	@media (min-width: 1920px) {
		font-size: ${({ theme }) => theme.fontSize.f4};
		line-height: ${({ theme }) => theme.lineHeight.line6};
	}
`;

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colors.pmmFooterViolet};
	font-size: ${({ theme }) => theme.fontSize.f10};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	text-decoration: none;
  	padding-right: 10px;
	@media(min-width: 1920px){
		font-size: ${({ theme }) => theme.fontSize.f4};
		//line-height: ${({ theme }) => theme.lineHeight.line6};
		line-height: 19px;
	}
`;

const PitaxCodeWrapper = styled.div`
	color: ${({ theme }) => theme.colors.pmmFooterViolet};
	font-size: ${({ theme }) => theme.fontSize.f10};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	text-decoration: none;
	text-align: center;
	margin-bottom: 20px;
	padding: 0 10px;
	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSize.f4};
		line-height: 19px;
		text-align: right;
		display: none;
	}
	a {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.pmmFooterViolet};
	}
	&.desktop {
		display: none;
		@media (min-width: 768px) {
			display: block;
			font-size: ${({ theme }) => theme.fontSize.f10};
			line-height: 19px;
			margin-bottom: 0;
			padding: 0;
		}
		@media (min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.f4};
			text-align: right;
		}
	}
`;
const LinkListWrapper = styled.ul`
	display: flex;
	flex-direction: column;

	li {
		text-align: center;
		margin-bottom: 30px;
	}
	@media (min-width: 768px) {
		flex-direction: row;
		width: 60%;
		justify-content: space-between;
		li {
			text-align: left;
			margin-right: 50px;
		}
	}
	@media (min-width: 1920px) {
		width: calc(100% - 860px);
		li {
			margin-right: 40px;
		}
	}
`;

const PrivacyPolicyRegulationsWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const CompanyInfo = styled.a`
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
	margin-bottom: 20px;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.pmmFooterViolet};

	@media (min-width: 768px) {
		width: 40%;
		margin-top: 0;
		margin-bottom: 0;
	}
	@media (min-width: 1280px) {
		justify-content: flex-start;
	}
	@media (min-width: 1920px) {
		width: 860px;
	}

	a {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.pmmFooterViolet};
	}
`;

const BackTopBtn = styled.div`
	@media (min-width: 768px) {
		position: absolute;
		bottom: 285px;
		right: 35px;
		width: 53px;
		height: 53px;
		background-image: url(${({ back }) => back});
		cursor: pointer;
		transition: transform 0.15s ease-in-out;

		&:hover {
			transform: scale(1.113);
		}
	}
	@media (min-width: 1280px) {
		right: 55px;
		bottom: 210px;
	}
	@media (min-width: 1920px) {
		right: 100px;
		bottom: 250px;
	}
`;

class Footer extends Component {
	state = {
		isInputActiveAndCheckboxVisible: false,
		email: '',
		privacyPolicyAgree: false,
		emailInvalid: false,
		emailInvalidMsg: '',
		isPopupVisible: false,
		isEmailCorrect: false,
		showIncorrectEmailInfo: false,
		isOldBrowserEdge: false,
		isWholeInfoShown: false,
	};

	initialState = {
		isInputActiveAndCheckboxVisible: false,
		email: '',
		privacyPolicyAgree: false,
		emailInvalidMsg: '',
		showIncorrectEmailInfo: false,
	};

	triggerInputAnimationAndShowCheckbox() {
		if (this.state.email === '') {
			this.setState({ isInputActiveAndCheckboxVisible: !this.state.isInputActiveAndCheckboxVisible });
		} else {
			this.setState({ isInputActiveAndCheckboxVisible: this.state.isInputActiveAndCheckboxVisible });
		}
	}

	markCheckbox() {
		this.setState({ privacyPolicyAgree: !this.state.privacyPolicyAgree });
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false);
		if (typeof window !== 'undefined') {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({ isOldBrowserEdge: isOldBrowser });
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false);
	}

	handleClickIfOutsideSelectWindow = e => {
		if (this.node.contains(e.target)) {
			return;
		}
		this.setState(this.initialState);
	};

	handleInputChange = event => {
		const target = event.target;
		const value = target.type === 'email' ? target.value : '';
		this.setState({ email: value });
	};

	togglePopup() {
		this.setState({ isPopupVisible: !this.state.isPopupVisible });
	}

	toggleWholeInfoShown() {
		this.setState({ isWholeInfoShown: !this.state.isWholeInfoShown });
	}

	handleSubmit = event => {
		event.preventDefault();
		try {
			this.sendEmail();
		} catch (err) {
			console.log(err);
		}
	};

	async sendEmail() {
		const data = new FormData();
		data.append('freshmailListId', 'bbmpe04a95');
		data.append('freshmailEmail', this.state.email);
		data.append('action', 'freshmail/freshmail/ajax');
		const rawResponse = await sendData(data);
		const responseData = await rawResponse.json();
		const fbq = window.fbq;

		if (responseData.status === 'OK') {
			this.setState({ isEmailCorrect: true });
			if (fbq) {
				fbq('trackCustom', 'AddToNewsletter', {
					value: this.state.email,
					info: 'dolaczono ze stopki',
				});
			}
			this.togglePopup();
		} else if (responseData.status === 'ERROR') {
			if (responseData.errors[0].code === 1304) {
				this.setState({ isEmailCorrect: false });
				this.togglePopup();
			} else if (responseData.errors[0].code === 0) {
				this.setState({ showIncorrectEmailInfo: true });
			} else {
				this.setState(this.initialState);
			}
		}
	}

	scrollTop = () => {
		window.scrollTo(0, 0);
	};

	render() {
		const {
			footerData,
			activeSite,
			socialMediaData,
			firstOtherLangPathArray,
			pageContext,
			translations,
			newsletterModalData,
			processingPersonalData,
		} = this.props;
		const { isWholeInfoShown, isOldBrowserEdge } = this.state;
		const footerNavItems =
			(footerData && footerData.stopkaNawigacja && footerData.stopkaNawigacja[0] && footerData.stopkaNawigacja[0].podstrony) || [];
		const footerInfoTableData = (footerData && footerData.stopkaDane && footerData.stopkaDane[0]) || [];
		const footerInfoTableTitle = (footerInfoTableData && footerInfoTableData.tytul) || '';
		const footerInfoTableRows = (footerInfoTableData && footerInfoTableData.tabela) || [];
		const footerBtnText = (footerInfoTableData && footerInfoTableData.przyciskTekst) || '';
		const toBtn =
			(footerInfoTableData &&
				footerInfoTableData.przyciskWpis &&
				footerInfoTableData.przyciskWpis[0] &&
				footerInfoTableData.przyciskWpis[0].slug) ||
			'';
		const hrefBtn = (footerInfoTableData && footerInfoTableData.przyciskLink) || '';
		const typeBtn =
			(footerInfoTableData &&
				footerInfoTableData.przyciskRodzaj &&
				footerInfoTableData.przyciskRodzaj[0] &&
				footerInfoTableData.przyciskRodzaj[0].title) ||
			'';
		const socialMedia = (socialMediaData && socialMediaData[0] && socialMediaData[0].socialMedia) || [];
		let socialMediaArray = [];
		socialMedia.forEach(icon => {
			socialMediaArray.push({
				logo: (icon.socialMediaLogo && icon.socialMediaLogo[0] && icon.socialMediaLogo[0].url) || '',
				logoOrange: (icon.socialMediaLogoOrange && icon.socialMediaLogoOrange[0] && icon.socialMediaLogoOrange[0].url) || '',
				logoStickyMenu:
					(icon.socialMediaLogoStickyMenu && icon.socialMediaLogoStickyMenu[0] && icon.socialMediaLogoStickyMenu[0].url) || '',
				link: icon.socialMediaLink || '',
			});
		});

		const pleoInfo = (footerData && footerData.stopkaPleo && footerData.stopkaPleo[0] && footerData.stopkaPleo[0].tekst) || '';
		const pleoUrl = (footerData && footerData.stopkaPleo && footerData.stopkaPleo[0] && footerData.stopkaPleo[0].pleoLink) || '';
		const bottomNav = (footerData && footerData.stopkaNawigacjaNaDole) || [];
		const arrowBackTop =
			(footerData &&
				footerData.stopkaPrzyciskPowrotNaGore &&
				footerData.stopkaPrzyciskPowrotNaGore[0] &&
				footerData.stopkaPrzyciskPowrotNaGore[0].url) ||
			[];
		const changeLangText = (translations && translations[0] && translations[0].tlumaczenieZmienJezyk) || '';
		const newsletterData = (footerData && footerData.stopkaNewsletter && footerData.stopkaNewsletter[0]) || '';
		const newsletterTitle = (newsletterData && newsletterData.naglowek) || '';
		let newsletterPlaceholder = (newsletterData && newsletterData.placeholder) || '';
		const newsletterBtnText = (newsletterData && newsletterData.przyciskNapis) || '';
		const newsletterTypeBtn =
			(newsletterData &&
				newsletterData.przyciskRodzaj &&
				newsletterData.przyciskRodzaj[0] &&
				newsletterData.przyciskRodzaj[0].title) ||
			'';
		const newsletterCheckboxText = (newsletterData && newsletterData.checkboxTekst) || '';
		const newsletterCheckboxLinkText = (newsletterData && newsletterData.checkboxLinkTekst) || '';
		// const newsletterCheckboxLink = (newsletterData && newsletterData.checkboxLink && newsletterData.checkboxLink[0] && newsletterData.checkboxLink[0].slug) || ""
		const newsletterEmailValidationInfo = (newsletterModalData && newsletterModalData.newsletterEmailValidationInfo) || '';

		const isInputActiveAndCheckboxVisible = this.state.isInputActiveAndCheckboxVisible;
		const classNameIfInputActive = isInputActiveAndCheckboxVisible ? 'active' : '';
		if (!this.state.isInputActiveAndCheckboxVisible && this.state.email !== '') {
			this.setState({ isInputActiveAndCheckboxVisible: true });
		}
		if (this.state.showIncorrectEmailInfo) {
			newsletterPlaceholder = '';
		}
		let isSubscriberAdded = this.state.isEmailCorrect;
		const showIncorrectMailInfo = this.state.showIncorrectEmailInfo;
		const classNameIfIncorrectMail = showIncorrectMailInfo ? 'wrong-email' : '';
		const iconClassName = this.state.isOldBrowserEdge ? ' edge' : '';

		const processingData =
			(processingPersonalData &&
				processingPersonalData.informacjaOPrzetwarzaniuDanychOsobowych &&
				processingPersonalData.informacjaOPrzetwarzaniuDanychOsobowych[0]) ||
			'';
		return (
			<FooterWrapper>
				<BackTopBtn back={arrowBackTop} onClick={this.scrollTop.bind(this)} />
				<FirstWrapper>
					<InfoWrapper>
						<h5>{footerInfoTableTitle}</h5>
						{footerInfoTableRows.map((item, key) => (
							<li key={key}>
								<span>{item.opis}</span>
								{item.dane}
							</li>
						))}
						<BtnWrapper>
							<Button
								onClick={() => fbqDonateBtnClicked('kliknieto ze stopki')}
								href={hrefBtn}
								btnType={typeBtn}
								to={`/${getSlug(toBtn, activeSite)}`}
								textBtn={footerBtnText}
							/>
						</BtnWrapper>
					</InfoWrapper>
					<NavListWrapper>
						{footerNavItems.map((item, key) => (
							<li key={key}>
								<FooterLink to={`/${getSlug(item.slug, activeSite)}`}>{item.title}</FooterLink>
							</li>
						))}
					</NavListWrapper>
				</FirstWrapper>
				<SecondWrapper>
					<SocialMediaAndNewsletterWrapper>
						<FooterSocialMediaWrapper>
							{socialMediaArray.map((icon, key) => (
								<Icon
									key={key}
									className={'footer orange-hover' + iconClassName}
									href={icon.link}
									target="_blank"
									rel="noopener noreferrer"
									back={icon.logo}
									backOrange={icon.logoOrange}
								/>
							))}
						</FooterSocialMediaWrapper>
						<NewsletterWrapper>
							<NewsletterTitle>{newsletterTitle}</NewsletterTitle>
							<form id="newsletter-form-footer" onSubmit={this.handleSubmit.bind(this)} ref={node => (this.node = node)}>
								<InputAndButtonWrapper>
									<InputWrapper
										onClick={this.triggerInputAnimationAndShowCheckbox.bind(this)}
										className={classNameIfInputActive}
										customPlaceholder={newsletterPlaceholder}
									>
										<StyledInput
											type="email"
											className={classNameIfInputActive + ' ' + classNameIfIncorrectMail}
											value={this.state.email}
											onChange={this.handleInputChange}
											required
										/>
										<IncorrectEmailInfo showIncorrectMailInfo={showIncorrectMailInfo}>
											{newsletterEmailValidationInfo}
										</IncorrectEmailInfo>
									</InputWrapper>
									<CheckboxInfoVisibilityWrapper>
										<CheckboxInfo
											isInputActiveAndCheckboxVisible={isInputActiveAndCheckboxVisible}
											isCheckboxClicked={this.markCheckbox.bind(this)}
											required
											isFooter={true}
											isSpanClicked={this.toggleWholeInfoShown.bind(this)}
											checked={this.state.privacyPolicyAgree}
											paragraphText={newsletterCheckboxText}
											spanText={newsletterCheckboxLinkText}
										></CheckboxInfo>
									</CheckboxInfoVisibilityWrapper>
									<ButtonWrapper>
										<Button
											isOldBrowserEdge={isOldBrowserEdge}
											form="newsletter-form-footer"
											type="submit"
											btnType={newsletterTypeBtn}
											textBtn={newsletterBtnText}
										/>
									</ButtonWrapper>
									{isWholeInfoShown && isInputActiveAndCheckboxVisible && (
										<WholeInfoProcessingPersonalData
											isFooter={true}
											isContact={false}
											isPayment={false}
											processingData={processingData}
											isSpanClicked={this.toggleWholeInfoShown.bind(this)}
										/>
									)}
								</InputAndButtonWrapper>
								<CheckboxInfoVisibilityWrapper className="big-screen">
									<CheckboxInfo
										isInputActiveAndCheckboxVisible={isInputActiveAndCheckboxVisible}
										isCheckboxClicked={this.markCheckbox.bind(this)}
										required
										isFooter={true}
										isSpanClicked={this.toggleWholeInfoShown.bind(this)}
										checked={this.state.privacyPolicyAgree}
										paragraphText={newsletterCheckboxText}
										spanText={newsletterCheckboxLinkText}
									></CheckboxInfo>
								</CheckboxInfoVisibilityWrapper>
							</form>
							<Popup
								newsletterModalData={newsletterModalData}
								isVisible={this.state.isPopupVisible}
								hide={this.togglePopup.bind(this)}
								isSubscriberAdded={isSubscriberAdded}
							/>
						</NewsletterWrapper>
					</SocialMediaAndNewsletterWrapper>
					<LangWrapper>
						<h6>{changeLangText}:</h6>
						<ChangeLanguageWrapper>
							<ChangeLanguage
								firstOtherLangPathArray={firstOtherLangPathArray}
								pageContext={pageContext}
								isMenuStickyVisible={false}
								displayForAllSizes={true}
								isInline
							/>
						</ChangeLanguageWrapper>
					</LangWrapper>
					<InfoWrapper className="mobile">
						<h5>{footerInfoTableTitle}</h5>
						{footerInfoTableRows.map((item, key) => (
							<li key={key}>
								<span>{item.opis}</span>
								{item.dane}
							</li>
						))}
						<BtnWrapper>
							{/*<ButtonWithText textBtn={footerBtnText} linkTo={`/${getSlug(toBtn, activeSite)}`}/>*/}
							<Button href={hrefBtn} btnType={typeBtn} to={`/${getSlug(toBtn, activeSite)}`} textBtn={footerBtnText} />
						</BtnWrapper>
					</InfoWrapper>
				</SecondWrapper>
				<ThirdWrapper>
					<LinkListWrapper>
						<PrivacyPolicyRegulationsWrapper>
							{bottomNav.map((item, key) => (
								<StyledLink key={key} to={`/${getSlug(item.slug, activeSite)}`}>
									<li> {item.title}</li>
								</StyledLink>
							))}
						</PrivacyPolicyRegulationsWrapper>
						<PitaxCodeWrapper className="desktop">
							<a href={'https://www.iwop.pl/pity/'} target="_blank" rel="noopener noreferrer">
								{activeSite === 'pl' ?  "Rozliczenie PIT we współpracy z Instytutem Wsparcia Organizacji Pozarządowych" : "PIT settlement in cooperation with the Institute for Supporting NGOs"}
							</a>{' '}
							{activeSite === 'pl' ? "w ramach projektu" : "within the project"}
							<a href={'https://www.pitax.pl/rozliczenie-pit/'} target="_blank" rel="noopener noreferrer">
								{' '}
								{activeSite === 'pl' ? "PITax.pl dla OPP" : "PITax.pl for OPP"}
							</a>
						</PitaxCodeWrapper>
					</LinkListWrapper>
					<CompanyInfo href={pleoUrl} target="_blank" rel="noopener noreferrer">
						{pleoInfo}
					</CompanyInfo>
					<PitaxCodeWrapper>
						<a href={'https://www.iwop.pl/pity/'} target="_blank" rel="noopener noreferrer">
							{activeSite === 'pl' ?  "Rozliczenie PIT we współpracy z Instytutem Wsparcia Organizacji Pozarządowych" : "PIT settlement in cooperation with the Institute for Supporting NGOs"}
						</a>{' '}
						{activeSite === 'pl' ? "w ramach projektu" : "within the project"}
						<a href={'https://www.pitax.pl/rozliczenie-pit/'} target="_blank" rel="noopener noreferrer">
							{activeSite === 'pl' ? "PITax.pl dla OPP" : "PITax.pl for OPP"}
						</a>
					</PitaxCodeWrapper>
				</ThirdWrapper>
			</FooterWrapper>
		);
	}
}

export default Footer;
